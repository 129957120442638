import axios from 'axios';
import { store } from 'redux/storage';
import { MODAL_GENERAL_ERROR } from 'redux/actions/types';
import { connectionSpeed } from 'utils/connection';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';

// if a request takes more than 2 seconds or whatever the timeout is set to, an error message pops up with the estimated connection speed
const connectionTimeout = (error) => {
	if (error?.code === 'ECONNABORTED' && error?.message?.includes('timeout')) {
		const speedMbps = store.getState().admin.networkSpeed;

		connectionSpeed();

		setTimeout(() => {
			store.dispatch({
				type: MODAL_GENERAL_ERROR,
				payload: {
					message: i18next.t('messages.modal.requestTimedOut'),
					info: `${i18next.t('messages.modal.estimatedConnectionSpeed')} ${speedMbps}`,
					heading: i18next.t('messages.modal.error'),
				},
			});
		}, 300);
	}
};

// log errors to Sentry
const logError = (error) => {
	const errorMessage = error?.data?.msg || error?.data?.message || error;

	if (errorMessage.includes('Error: Invalid credentials')) {
		return;
	} else {
		Sentry.captureException(errorMessage);
	}
};

// put response interceptor logic here
const responseInterceptors = axios.interceptors.response.use(
	(response) => response,
	(error) => {
		connectionTimeout(error.response);
		logError(error.response);
		return Promise.reject(error);
	},
);

const requestInterceptors = axios.interceptors.request.use(
	(request) => request,
	(error) => {
		connectionTimeout(error);
		logError(error.request);
		return Promise.reject(error);
	},
);

export const interceptors = {
	response: responseInterceptors,
	request: requestInterceptors,
};
