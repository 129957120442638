import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from 'react-verification-code-input';
import { useHistory } from 'react-router-dom';

// mui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// assets/components
import SetupEcoCard from 'pages/Process/EcoCard/SetupEcoCard';
import TwoStepAuthModal from 'components/TwoStepAuth/TwoStepAuthModal';
import PortalTextField from 'components/UIKit/PortalTextField';
import CustomButton from 'components/UIKit/CustomButton';
// import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import GoogleAddressAutoComplete from 'components/UIKit/GoogleAddressAutoComplete';
import { RESET_TWO_FACTOR_AUTH } from 'redux/actions/types';
import {
	verificationRequest,
	verificationStart,
	emailAndPhoneVerification,
	fullLogin,
} from 'redux/actions/auth';
import { setSnackbarMessage } from 'redux/actions/userInterface';
import { screenEmailAndPhone } from 'utils/validation';
import { updateCustomer } from 'redux/actions/user';

const useStyles = makeStyles((theme) => ({
	main: {
		display: 'flex',
		width: '100%',
	},
	form: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	inputs: {
		width: '650px',
		marginBottom: '60px',
		'& .MuiInputBase-root': {
			marginTop: '20px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			maxWidth: '100vw',
		},
	},
	apt: {
		color: theme.palette.secondary.dark,
		display: 'flex',
		marginTop: '20px',
		marginLeft: '110px',
		alignItems: 'center',
		'& .MuiTypography-root': {
			marginLeft: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '50px',
		},
	},
	button: {
		marginTop: '40px',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
	buttonGroup: {
		marginTop: '40px',
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '650px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100vw',
		},
	},
	captcha: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	question: {
		marginBottom: '20px',
	},
	captchaImages: {
		display: 'flex',
		justifyContent: 'center',
		maxWidth: '350px',
		'& img': {
			height: '150px',
			width: 'auto',
			margin: '10px',
		},
	},
	verificationCodes: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& h6': {
			textAlign: 'center',
		},
	},
	verificationInput: {
		marginTop: '50px',
		marginBottom: '20px',
	},
	icon: {
		marginTop: '20px',
		color: theme.palette.primary.dark,
	},
	inputPassword: {
		'-webkit-text-security': 'disc',
	},
	cardSetup: {
		position: 'absolute',
		height: '70%',
		width: '100%',
	},
}));

export default function EcoCardUserInfo({ missingInfo, returnToPaymentSelect }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const uniqueId = uuid().slice(0, 8);
	const auth = useSelector((state) => state.auth);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [firstName, setFirstName] = useState('');
	const [captchaData, setCaptchaData] = useState(null);
	const [lastName, setLastName] = useState('');
	const [showApt, setShowApt] = useState(false);
	const [password, setPassword] = useState('');
	const [showPasswordText, setShowPasswordText] = useState(false);
	const [showTwoStepAuth, setShowTwoStepAuth] = useState(false);
	const [verificationIds, setVerificationIds] = useState({});
	const [phoneVerificationCode, setPhoneVerificationCode] = useState('');
	const [captchaCount, setCaptchaCount] = useState(null);
	const [address, setAddress] = useState(null);
	const [step, setStep] = useState(1);
	const [apt, setApt] = useState('');
	const firstNameRequired = missingInfo?.includes('first_name');
	const lastNameRequired = missingInfo?.includes('last_name');
	const phoneNumberRequired = missingInfo?.includes('phone_number');
	const addressRequired =
		missingInfo?.includes('zipcode') ||
		missingInfo?.includes('street') ||
		missingInfo?.includes('city');

	useEffect(() => {
		handleTwoStepAuth();
	}, [auth.twoFactorAuth]);

	const handleTwoStepAuth = () => {
		if (auth.twoFactorAuth.required) {
			setShowTwoStepAuth(true);
		}
	};

	const clearData = () => {
		setPhoneNumber('');
		setFirstName('');
		setCaptchaData(null);
		setPassword('');
		setLastName('');
		setShowApt(false);
		setVerificationIds({});
		setPhoneVerificationCode('');
		setCaptchaCount(null);
		setAddress(null);
		setStep(1);
	};

	const cancel = () => {
		clearData();
		if (history.location.state?.setupEcoCard) {
			history.push('/express');
		} else {
			returnToPaymentSelect();
		}
	};

	const back = () => {
		setStep((prevState) => prevState - 1);
	};

	const handlePasswordVisibility = () => {
		setShowPasswordText(!showPasswordText);
	};

	const handleShowApt = () => {
		setShowApt(true);
	};

	const handleSetAddress = (selectedAddress) => {
		const data = selectedAddress.address_components;

		const formattedAddress = {
			street: data[1].long_name,
			housenumber: data[0].long_name,
			state: data[5].short_name,
			city: data[2].long_name,
			apt: apt,
			zipcode: data[7].long_name,
		};
		setAddress(formattedAddress);
	};

	const submit = (token) => {
		const customer = {
			type: 0,
			first_name: firstName,
			last_name: lastName,
			street: `${address?.housenumber} ${address?.street}`,
			apt: apt,
			city: address?.city,
			state: address?.state,
			zipcode: address?.zipcode,
			phone_number: phoneNumber.replace(/[- )(]/g, ''),
		};

		// delete fields that aren't needed so only the appropriate data gets updated
		!phoneNumber && delete customer.phone_number;
		!firstName && delete customer.first_name;
		!lastName && delete customer.last_name;
		!address?.street && delete customer.street;
		!apt && delete customer.apt;
		!address?.city && delete customer.city;
		!address?.state && delete customer.state;
		!address?.zipcode && delete customer.zipcode;
		!phoneNumber && delete customer.phone_number;

		dispatch(updateCustomer(customer, token))
			.then(() => {
				setStep(5);
			})
			.catch(() => {
				dispatch(setSnackbarMessage(t('mobileSignup.errorUserInfo'), 'error'));
			});
	};

	// formats to (###) ###-####
	const formatPhoneNumber = (input) => {
		input = input.replace(/\D/g, '').substring(0, 10); // Strip everything but 1st 10 digits
		var size = input.length;
		if (size > 0) {
			input = '(' + input;
		}
		if (size > 3) {
			input = input.slice(0, 4) + ') ' + input.slice(4);
		}
		if (size > 6) {
			input = input.slice(0, 9) + '-' + input.slice(9);
		}
		setPhoneNumber(input);
	};

	// makes a request to receive captcha data like photos
	const handleVerification = async () => {
		const phone = phoneNumber.replace(/[- )(]/g, '');
		const emailInput = '';
		const validation = await screenEmailAndPhone(emailInput, phone);

		if (!firstName && firstNameRequired) {
			dispatch(setSnackbarMessage(t('ecoCardInfo.enterFirstName'), 'error'));
		} else if (!lastName && lastNameRequired) {
			dispatch(setSnackbarMessage(t('ecoCardInfo.enterLastName'), 'error'));
		} else if (phoneNumberRequired && !validation.ok) {
			dispatch(setSnackbarMessage(t('ecoCardInfo.invalidPhone'), 'error'));
		} else if (!address && addressRequired) {
			dispatch(setSnackbarMessage(t('ecoCardInfo.selectFromDropDown'), 'error'));
		} else if (phoneNumberRequired && validation.ok) {
			dispatch(verificationRequest(emailInput, phone))
				.then((res) => {
					setCaptchaData(res.data);
					setStep(2);
				})
				.catch(() => {
					dispatch(setSnackbarMessage(t('mobileSignup.errorUserInfo'), 'error'));
				});
		} else {
			setStep(4);
		}
	};

	// submits the captcha answer and the associated id returned earlier
	const handleCaptchaSubmit = () => {
		dispatch(verificationStart(captchaCount, captchaData.id))
			.then((res) => {
				setVerificationIds(res.data);
				setStep(3);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	// takes the code input by customer and submits with the associated id returned earlier
	const handleVerificationCodes = () => {
		const phoneVerification = {
			id: verificationIds.id_phone_number_auth,
			code: phoneVerificationCode,
		};

		const emailPhoneVerification = {
			id: '',
			code: '',
		};

		dispatch(emailAndPhoneVerification(emailPhoneVerification, phoneVerification))
			.then(() => {
				setStep(4);
			})
			.catch(() => {
				dispatch(setSnackbarMessage(t('mobileSignup.errorConfirmationCodes'), 'error'));
			});
	};

	const handleSubmitPassword = () => {
		dispatch(fullLogin(auth.customer.email, password));
	};

	const closeTwoStepAuth = () => {
		dispatch({ type: RESET_TWO_FACTOR_AUTH });
		setShowTwoStepAuth(false);
	};

	const handleUpdateCustomer = (token) => {
		submit(token);
	};

	return (
		<>
			<Box className={classes.form}>
				<Box className={classes.inputs}>
					{step === 1 && (
						<>
							<>
								{firstNameRequired && (
									<PortalTextField
										id={uniqueId}
										name='data-cy-signup-firstname'
										placeholder={t('mobileSignup.firstName')}
										fullWidth
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								)}
								{lastNameRequired && (
									<PortalTextField
										id={uniqueId}
										placeholder={t('mobileSignup.lastName')}
										name='data-cy-signup-lastname'
										fullWidth
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
									/>
								)}
							</>
							<>
								{phoneNumberRequired && (
									<PortalTextField
										id={uniqueId}
										name='data-cy-signup-number'
										placeholder={t('mobileSignup.phoneNumber')}
										value={phoneNumber}
										fullWidth
										onChange={(e) => formatPhoneNumber(e.target.value)}
									/>
								)}
								{addressRequired && (
									<>
										<GoogleAddressAutoComplete
											id={uniqueId}
											name='data-cy-signup-address'
											setAddress={handleSetAddress}
										/>

										{!showApt && (
											<Box className={classes.apt} onClick={handleShowApt}>
												<AddCircleOutlineIcon />
												<Typography variant='body2'>
													<strong>{t('mobileSignup.addApt')}</strong>
												</Typography>
											</Box>
										)}
										{showApt && (
											<PortalTextField
												id={uniqueId}
												placeholder={t('mobileSignup.apt')}
												fullWidth
												value={apt}
												onChange={(e) => setApt(e.target.value)}
											/>
										)}
									</>
								)}
								<Box className={classes.buttonGroup}>
									<CustomButton variant='alternate' onClick={cancel}>
										{t('common.buttons.cancel')}
									</CustomButton>
									<CustomButton onClick={handleVerification}>
										{t('common.buttons.continue')}
									</CustomButton>
								</Box>
							</>
						</>
					)}

					{step === 2 && (
						<Box className={classes.captcha}>
							<Typography className={classes.question}>
								{captchaData.looking_for === 'plastic'
									? t('mobileSignup.howManyPlastic')
									: t('mobileSignup.howManyAlu')}
							</Typography>
							<Box className={classes.captchaImages}>
								{captchaData.images?.map((image, index) => (
									<img key={index} src={`data:image/png;base64, ${image}`} />
								))}
							</Box>
							<PortalTextField
								id={uniqueId}
								name='data-cy-signup-captcha'
								placeholder={t('mobileSignup.enterCount')}
								value={captchaCount}
								onChange={(e) => setCaptchaCount(e.target.value)}
							/>
							<Box className={classes.buttonGroup}>
								<CustomButton onClick={back}>{t('common.buttons.back')}</CustomButton>
								<CustomButton onClick={handleCaptchaSubmit}>
									{t('common.buttons.continue')}
								</CustomButton>
							</Box>
						</Box>
					)}
					{step === 3 && (
						<Box className={classes.verificationCodes}>
							<Typography>{t('mobileSignup.checkForCodes')}</Typography>
							{phoneNumber && (
								<Box className={classes.verificationInput}>
									<Typography>{t('mobileSignup.phoneCode')}</Typography>
									<ReactCodeInput
										className={classes.numberInput}
										id='phone_verification_code'
										name='phone verification code'
										type='number'
										fields={4}
										autoFocus={false}
										onChange={(e) => setPhoneVerificationCode(e)}
									/>
								</Box>
							)}
							<Box className={classes.buttonGroup}>
								<CustomButton onClick={back}>{t('common.buttons.back')}</CustomButton>
								<CustomButton onClick={handleVerificationCodes}>
									{t('mobileSignup.submit')}
								</CustomButton>
							</Box>
						</Box>
					)}
					{step === 4 && (
						<>
							<Box className={classes.review}>
								<PortalTextField
									id={uniqueId}
									className={!showPasswordText && classes.inputPassword}
									passwordInput
									name='data-cy-signup-password'
									placeholder={t('signUp.password')}
									handlePasswordVisibility={handlePasswordVisibility}
									isPasswordVisible={showPasswordText}
									fullWidth
									onChange={(e) => setPassword(e.target.value)}
								/>
								<Box className={classes.buttonGroup}>
									<CustomButton variant='alternate' onClick={cancel}>
										{t('common.buttons.cancel')}
									</CustomButton>
									<CustomButton onClick={handleSubmitPassword}>
										{t('mobileSignup.submit')}
									</CustomButton>
								</Box>
							</Box>
							<TwoStepAuthModal
								open={showTwoStepAuth}
								emailOrPhone={auth.customer.email}
								password={password}
								close={closeTwoStepAuth}
								updateCustomer={handleUpdateCustomer}
							/>
						</>
					)}
				</Box>
			</Box>
			{step === 5 && (
				<Box className={classes.cardSetup}>
					<SetupEcoCard returnToPaymentSelect={returnToPaymentSelect} />
				</Box>
			)}
		</>
	);
}
