import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';
import { loadFromLocalStorage, saveToLocalStorage } from 'utils/localStorage';

const adminState = loadFromLocalStorage('admin');
const processState = loadFromLocalStorage('process');
const preloadedState = { ...adminState, ...processState };

const middleware = [thunk];
const devTools =
	process.env.NODE_ENV === 'production'
		? applyMiddleware(...middleware)
		: composeWithDevTools(applyMiddleware(...middleware));

export const store = createStore(rootReducer, preloadedState, devTools);

store.subscribe(() => {
	const state = store.getState();
	saveToLocalStorage('admin', { admin: state.admin });
	saveToLocalStorage('process', { process: state.process });
});
