// lib
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// components
import { getDailyWeightLimits } from 'redux/actions/process';
import AllowedContainersImages from 'pages/Process/LoadHopper/AllowedContainersImages';
import loadHopperPlasticAlu from 'assets/images/recycletek-hopper-alu-plastic.png';
import getOperatorIcon from 'assets/images/recycletek-get-operator-icon.png';
import { generalErrorModal } from 'redux/actions/modal';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.primary.dark,
		borderRadius: '10px',
		height: '380px',
		color: '#FFF',
		width: '850px',
		margin: '28px 0px 14px 0px',
		display: 'flex',
		flexDirection: 'column',
	},
	main: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '100%',
	},
	instructions: {
		margin: '-54px 8px 0px -32px',
	},
	stepOne: {
		animation: '$fadeInStepOne 300ms',
	},
	stepTwo: {
		animation: '$fadeInStepTwo 4500ms',
	},
	stepThree: {
		animation: '$fadeInStepThree 4500ms',
	},
	instruction: {
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: '10px',
		color: '#004F71',
		width: 'max-content',
		padding: '8px 18px 8px 14px',
		marginTop: '18px',
		backgroundColor: '#FFF',
		overflow: 'hidden',
		boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
		'& .MuiTypography-root': {
			fontWeight: '200',
		},
	},
	instructionNotes: {
		margin: '18px 0px 0px 32px',
		minHeight: '100px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingLeft: '12px',
		maxWidth: '430px',
		animation: '$fadeInNotes 4500ms',
		'& .MuiTypography-h6': {
			color: '#FFFFFF',
		},
	},
	instructionsIcon: {
		height: '152px',
	},
	instructionsIconSmall: {
		height: '118px',
	},
	infoSection: {
		alignItems: 'center',
		backgroundColor: '#FFF',
		boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
		borderRadius: '10px',
		border: '1px solid rgba(0,0,0,0.3)',
		color: '#004F71',
		display: 'flex',
		justifyContent: 'center',
		height: '360px',
		marginTop: '-22px',
		marginRight: '-28px',
		position: 'relative',
		width: '440px',
	},
	infoSectionSmall: {
		width: '260px',
		height: '270px',
		marginTop: '120px',
		marginRight: '-28px',
	},
	operatorIcon: {
		height: '240px',
		width: 'auto',
	},
	allowedContainers: {
		position: 'absolute',
		marginTop: '20px',
		animation: '$fadeIn 5500ms',
		animationIterationCount: 'infinite',
		animationTimingFuction: 'ease-in-out',
		animationDirection: 'alternate',
	},
	hopperIcon: {
		position: 'absolute',
		height: '240px',
		width: 'auto',
		animation: '$fadeInSecondary 5500ms',
		animationIterationCount: 'infinite',
		animationTimingFuction: 'ease-in-out',
		animationDirection: 'alternate',
	},
	['@keyframes fadeIn']: {
		'0%': { opacity: 1 },
		'50%': { opacity: 1 },
		'100%': { opacity: 0 },
	},
	['@keyframes fadeInSecondary']: {
		'0%': { opacity: 0 },
		'50%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
	['@keyframes fadeInStepOne']: {
		'0%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
	['@keyframes fadeInStepTwo']: {
		'0%': { opacity: 0 },
		'20%': { opacity: 0 },
		'60%': { opacity: 1 },
		'100%': { opacity: 1 },
	},
	['@keyframes fadeInStepThree']: {
		'0%': { opacity: 0 },
		'60%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
	['@keyframes fadeInNotes']: {
		'0%': { opacity: 0 },
		'80%': { opacity: 0 },
		'100%': { opacity: 1 },
	},
}));

// TODO: update the loadHopperIcon to display either aluminum or plastic, not both
export default function LoadHopperInstructions({ type }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.auth.language);
	const location = useSelector((state) => state.admin.state);
	const limits = useSelector((state) => state.process.dailyWeightLimits);
	const selectedMaterial = useSelector((state) => state.materialToLoad);
	const [instructions, setInstructions] = useState('');

	// todo: 'type' is fed plastic aluminum or other so need to adjust the combination types for that

	// general material grouping vars
	const plastic = type === 'plastic';
	const aluminum = type === 'aluminum';
	const other = type === 'other';

	const infoSection = clsx({
		[classes.infoSection]: true,
		[classes.infoSectionSmall]: other,
	});

	useEffect(() => {
		if (location === 'CA') {
			dispatch(getDailyWeightLimits()).then(() => weightLimitWarning());
		}
	}, []);

	// notifies user if they are close the weight limit (CA only)
	const weightLimitWarning = () => {
		const containers = limits.withinTenPercent.join(', ');

		if (containers.length > 0) {
			dispatch(
				generalErrorModal(
					t('messages.modal.nearWeightLimit', { containers }),
					null,
					t('messages.modal.warning'),
				),
			);
		}
	};

	useEffect(() => {
		getInstructions();
	}, [selectedMaterial]);

	const getInstructions = () => {
		let steps = {};

		if (plastic) {
			steps = {
				1: t('beginProcess.steps.plastic.one'),
				2: t('beginProcess.steps.plastic.two'),
				3: t('beginProcess.steps.plastic.three'),
			};
		} else if (aluminum) {
			steps = {
				1: t('beginProcess.steps.aluminum.one'),
				2: t('beginProcess.steps.aluminum.two'),
				3: t('beginProcess.steps.aluminum.three'),
			};
		} else if (other) {
			steps = {
				1: t('beginProcess.steps.notAllowed.one'),
				2: t('beginProcess.steps.notAllowed.two'),
				3: t('beginProcess.steps.notAllowed.three'),
			};
		}

		setInstructions(steps);
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.main}>
				<Box className={classes.instructions}>
					<Box className={`${classes.instruction} ${classes.stepOne}`}>
						<Typography variant='h5'>{instructions[1]}</Typography>
					</Box>
					<Box className={`${classes.instruction} ${classes.stepTwo}`}>
						<Typography variant='h5'>{instructions[2]}</Typography>
					</Box>
					<Box className={`${classes.instruction} ${classes.stepThree}`}>
						<Typography variant='h5'>{instructions[3]}</Typography>
					</Box>
					<Box className={classes.instructionNotes}>
						{!other && (
							<Typography variant={'h6'}>
								{t('beginProcess.differentMaterialsInstructions')}
							</Typography>
						)}
					</Box>
				</Box>
				<Box className={infoSection}>
					{!other && (
						<>
							<Box className={classes.allowedContainers}>
								<AllowedContainersImages />
							</Box>
							<img
								className={classes.hopperIcon}
								src={loadHopperPlasticAlu}
								alt='load hopper icon'
							/>
						</>
					)}
					{type === 'other' && (
						<img
							className={classes.operatorIcon}
							src={getOperatorIcon}
							alt={'alert operator icon'}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
}
