import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

// NOTE: currently not in use 08/28/2023, was removed because it was logging users out to quickly and
// not enough time to put in more materials if needed. Will keep component in case it needs to be used again
export default function AutoLogoutCount({ logout, finishProcess }) {
	const { t, i18n } = useTranslation();
	const [counter, setCounter] = useState(90);

	// count down for the 'it looks like you are inactive' modal (id = 11)
	useEffect(() => {
		const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		if (counter === 0) {
			logout();
		}
		return () => clearInterval(timer);
	}, [counter]);

	return (
		<>
			<Typography variant='body2'>
				{counter} {t('messages.modal.autoLogout')}
			</Typography>
		</>
	);
}
