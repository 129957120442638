// lib
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// mui
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// components
import RecycletekLogo from 'components/Background/RecycletekLogo';
import { logout } from 'redux/actions/auth';

// utils/lib
import {DELAY_MID} from 'lib/constants';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100vh', // 101 because bottom was showing some extra space on the kiosk screen
	},
	main: {
		display: 'flex',
		marginTop: '10%',
		justifyContent: 'center',
		textAlign: 'center',
		paddingBottom: '100px',
	},
}));

export default function ThankYou() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const language = useSelector((state) => state.auth.language);
	const express = useSelector((state) => state.admin.express);

	useEffect(() => {
		setTimeout(
			() => {
				logoutUser();
			},
			express ? 2500 : DELAY_MID,
		);
	}, []);

	const logoutUser = () => {
		dispatch(logout()).then(() => {
			history.push('/sign-in');
		});
	};

	return (
		<Box className={classes.container}>
			<RecycletekLogo />
			<Grid container component='main' className={classes.main}>
				<Typography variant='h3'>
					<Trans>{t('thankYou.thanks')}</Trans>
				</Typography>
			</Grid>
		</Box>
	);
}
