import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// mui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from '@material-ui/core/Slider';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';

// components
import { ADD_SCRAP_ITEM } from 'redux/actions/types';
import CustomButton from 'components/UIKit/CustomButton';
import AdminCounterInput from 'components/Admin/AdminCounterInput';
import AdminInput from 'components/Admin/AdminInput';
import AnimatedCheckmark from 'components/UIKit/AnimatedCheckmark/AnimatedCheckmark';

// lib/utils
import { sizes } from 'lib/sizes';
import { getFormattedWeight } from 'utils/formatWeight';
import { DELAY_SHORT } from 'lib/constants';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiRadio-root': {
			color: theme.palette.grey.main,
			margin: '8px',
		},
	},
	radioLabel: {
		position: 'absolute',
		top: '10px',
		left: '10px',
	},
	buttonGroup: {
		marginTop: '-40px',
		'& button': {
			margin: '10px 40px',
		},
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-evenly',
	},
	weightInputs: {
		marginTop: '-40px',
	},
	slider: {
		width: '410px',
		paddingTop: '15px',
		'& .MuiSlider-root': {
			color: theme.palette.secondary.main,
			'& .MuiSlider-rail': {
				height: '11px',
				borderRadius: '8px',
				width: '103%',
			},
			'& .MuiSlider-track': {
				height: '11px',
				borderRadius: '8px',
			},
			'& .MuiSlider-mark': {
				height: '10px',
			},
			'& .MuiSlider-thumb': {
				height: '28px',
				width: '28px',
				marginTop: '-8px',
			},
			'& .MuiSlider-valueLabel': {
				left: 'unset',
				marginTop: '-24px',
				fontSize: '16px',
				'& span': {
					height: '52px',
					width: '52px',
					'& span': {
						paddingTop: '17px',
						textAlign: 'center',
					},
				},
			},
			[theme.breakpoints.down('lg')]: {
				width: '345px',
			},
		},
	},
	selectSize: {
		position: 'absolute',
		top: '55px',
		left: '0px',
		[theme.breakpoints.down('lg')]: {
			left: '10px',
		},
	},
	count: {
		position: 'absolute',
		top: '125px',
		left: '84px',
		[theme.breakpoints.down('lg')]: {
			left: '10px',
		},
	},
	submitted: {
		minHeight: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	footer: {
		position: 'absolute',
		bottom: '10px',
		'& button': {
			margin: '0px 50px',
		},
	},
}));

const initScrapEntry = {
	category: '',
	weight: {
		gross: '',
		tare: '',
	},
	size: {
		select: 16, // actual weight in oz
		slider: 40, // position of the slider, not the actual weight
	},
	count: 0,
};

const sliderMarks = [
	{
		value: 10,
		realValue: sizes[0].value,
	},
	{
		value: 20,
		realValue: sizes[1].value,
	},
	{
		value: 30,
		realValue: sizes[2].value,
	},
	{
		value: 40,
		realValue: sizes[3].value,
	},
	{
		value: 50,
		realValue: sizes[4].value,
	},
	{
		value: 60,
		realValue: sizes[5].value,
	},
	{
		value: 70,
		realValue: sizes[6].value,
	},
	{
		value: 80,
		realValue: sizes[7].value,
	},
];

export default function ScrapInputs({ show, materialType, doneEdit, review }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [view, setView] = useState('select-category');
	const [scrapEntry, setScrapEntry] = useState(initScrapEntry);
	const [showSubmitted, setShowSubmitted] = useState(false);

	const handleCategorySelect = (e) => {
		setScrapEntry((prevState) => ({
			...prevState,
			category: e.target.value,
		}));
		setTimeout(() => {
			setView('entry-type');
		}, 600); // For UI interaction
	};

	const handleGrossWeight = (weightEntry) => {
		const formattedWeight = getFormattedWeight(weightEntry);

		setScrapEntry((prevState) => ({
			...prevState,
			weight: {
				...prevState.weight,
				gross: formattedWeight,
			},
		}));
	};

	const handleTareWeight = (weightEntry) => {
		const formattedWeight = getFormattedWeight(weightEntry);

		setScrapEntry((prevState) => ({
			...prevState,
			weight: {
				...prevState.weight,
				tare: formattedWeight,
			},
		}));
	};

	const backToSelectCategory = () => {
		setScrapEntry((prevState) => ({
			...prevState,
			category: '',
		}));
		setView('select-category');
	};

	const backToSelectEntryType = () => {
		setScrapEntry((prevState) => ({
			...prevState,
			weight: {
				gross: '',
				tare: '',
			},
			size: {
				select: 16,
				slider: 40,
			},
			count: 0,
		}));
		setView('entry-type');
	};

	const save = () => {
		const isWeightEntry = scrapEntry.weight.gross;
		const isSizeEntry = scrapEntry.count;
		const netWeight = Number(scrapEntry.weight.gross) - Number(scrapEntry.weight.tare);
		const weightEntry = {
			category: scrapEntry.category,
			weight_lbs: Number(netWeight.toFixed(1)),
		};
		const sizeEntry = {
			category: scrapEntry.category,
			count: scrapEntry.count,
			size_oz: scrapEntry.size.select,
		};
		const entry = (isWeightEntry && weightEntry) || (isSizeEntry && sizeEntry);

		dispatch({
			type: ADD_SCRAP_ITEM,
			payload: {
				material: materialType,
				entry: entry,
			},
		});
		setShowSubmitted(true);
		setTimeout(() => {
			setShowSubmitted(false);
		}, DELAY_SHORT);

		setScrapEntry(initScrapEntry);
		review && doneEdit(); //only when editing scrap inputs from the review page
		setView('select-category');
	};

	const valueText = (selectedValue) => {
		let label = '';

		sliderMarks.map((mark) => {
			if (mark.value === selectedValue) {
				label = `${mark.realValue} oz`;
			}
		});

		return label;
	};

	const handleSizeSelect = (e) => {
		const mark = sliderMarks.filter((mark) => mark.realValue === e.target.value);

		setScrapEntry((prevState) => ({
			...prevState,
			size: {
				select: e.target.value,
				slider: mark[0].value,
			},
		}));
	};

	const handleSizeSlider = (event, newValue) => {
		const mark = sliderMarks.filter((mark) => mark.value === newValue);

		setScrapEntry((prevState) => ({
			...prevState,
			size: {
				select: mark[0].realValue,
				slider: newValue,
			},
		}));
	};

	const increaseCount = (count) => {
		setScrapEntry((prevState) => ({
			...prevState,
			count: prevState.count + count,
		}));
	};

	const decreaseCount = (count) => {
		if (scrapEntry.count > 0)
			setScrapEntry((prevState) => ({
				...prevState,
				count: prevState.count - count,
			}));
	};

	const handleCountChange = (event) => {
		setScrapEntry((prevState) => ({
			...prevState,
			count: Number(event.target.value.replace(/\D/g, '')),
		}));
	};

	return (
		<>
			{show && (
				<Box className={classes.container}>
					{!showSubmitted && (
						<>
							{view === 'select-category' && (
								<Box>
									<FormControl component='fieldset' error='test'>
										<RadioGroup
											aria-label='material group'
											name='material-group'
											value={scrapEntry.category}
											onChange={handleCategorySelect}
										>
											<FormControlLabel
												value='line_breakage'
												control={<Radio />}
												label={t('scrap.lineBreakage')}
											/>
											<FormControlLabel
												value='prev_redeemed_or_baled'
												control={<Radio />}
												label={t('scrap.previouslyRedeemed')}
											/>
											<FormControlLabel
												value='out_of_state'
												control={<Radio />}
												label={t('scrap.outOfState')}
											/>
											<FormControlLabel
												value='non_redemption'
												control={<Radio />}
												label={t('scrap.nonRedemption')}
											/>
										</RadioGroup>
									</FormControl>
								</Box>
							)}
							{view === 'entry-type' && (
								<>
									<Box className={classes.buttonGroup}>
										<CustomButton
											variant='secondary'
											size='expanded'
											onClick={() => setView('select-size')}
										>
											{t('scrap.selectASize')}
										</CustomButton>
										<CustomButton
											variant='secondary'
											size='expanded'
											onClick={() => setView('weight-entry')}
										>
											{t('scrap.enterWeight')}
										</CustomButton>
									</Box>
									<Box className={classes.footer}>
										<CustomButton
											variant='alternate'
											size='small'
											onClick={backToSelectCategory}
										>
											{t('common.buttons.back')}
										</CustomButton>
									</Box>
								</>
							)}
							{view === 'weight-entry' && (
								<>
									<Box className={`${classes.row} ${classes.weightInputs}`}>
										<AdminInput
											name='cy-scrap-gross-weight'
											label={t('admin.enterGrossWeight')}
											value={scrapEntry.weight.gross}
											input='number'
											handleChange={(e) => handleGrossWeight(e.target)}
										/>
										<AdminInput
											name='cy-scrap-tare-weight'
											label={t('admin.tareWeight')}
											value={scrapEntry.weight.tare}
											input='number'
											handleChange={(e) => handleTareWeight(e.target)}
										/>
									</Box>
									<Box className={classes.footer}>
										<CustomButton
											variant='alternate'
											size='small'
											onClick={backToSelectEntryType}
										>
											{t('common.buttons.back')}
										</CustomButton>
										<CustomButton size='small' onClick={save}>
											{t('common.buttons.save')}
										</CustomButton>
									</Box>
								</>
							)}
							{view === 'select-size' && (
								<>
									<Box>
										<Box className={`${classes.row} ${classes.selectSize}`}>
											<div name='cy-scrap-size-select'>
												<AdminInput
													label={t('scrap.selectSize')}
													type='select'
													selectValue={scrapEntry.size.select}
													selectData={sizes}
													handleChange={handleSizeSelect}
												/>
											</div>
											<Box className={classes.slider}>
												<Slider
													valueLabelFormat={valueText}
													aria-labelledby='discrete-slider'
													valueLabelDisplay='on'
													step={10}
													min={10}
													max={80}
													value={scrapEntry.size.slider}
													marks={sliderMarks}
													onChange={handleSizeSlider}
												/>
											</Box>
										</Box>
										<Box className={classes.count}>
											<AdminCounterInput
												name='cy-scrap-counter'
												label={t('scrap.count')}
												value={scrapEntry.count}
												handleChange={handleCountChange}
												increaseCount={increaseCount}
												decreaseCount={decreaseCount}
											/>
										</Box>
									</Box>
									<Box className={classes.footer}>
										<CustomButton
											variant='alternate'
											size='small'
											onClick={backToSelectEntryType}
										>
											{t('common.buttons.back')}
										</CustomButton>
										<CustomButton
											size='small'
											disabled={scrapEntry.count <= 0}
											onClick={save}
										>
											{t('common.buttons.save')}
										</CustomButton>
									</Box>
								</>
							)}
						</>
					)}
					{showSubmitted && (
						<Box className={classes.submitted}>
							<Typography>{t('messages.success.scrapSaved')}</Typography>
							<AnimatedCheckmark />
						</Box>
					)}
				</Box>
			)}
		</>
	);
}
