import * as Sentry from '@sentry/react';
import { store } from 'redux/storage';
import { SET_DRYRUN, SET_IS_EMAIL_RECEIPT_ON, SET_NEW_VERSION_AVAILABLE } from 'redux/actions/types';
import { INDEX_HTML_DEPLOYMENT_URL } from 'lib/urls';

// sets dryrun status and email confirmation receipts based on environment
// dryrun is set to true if localhost
// transaction email receipt is off if localhost
export const configEnvironmentSettings = () => {
	const dryrun = store.getState().process.dryrun;

	if (dryrun === 'unset') {
		store.dispatch({
			type: SET_DRYRUN,
			payload: isDevelopmentEnv(),
		});
		store.dispatch({
			type: SET_IS_EMAIL_RECEIPT_ON,
			payload: !isDevelopmentEnv(),
		});
	}
};

// for making adjustments based on prod/dev
export const isDevelopmentEnv = () => {
	return process.env.NODE_ENV === 'development';
};

export const initSentry = () => {
	Sentry.init({
		dsn: 'https://e94c037a3d0f4066527a5f4f04cce19b@o4507392042991616.ingest.us.sentry.io/4507392060817408',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				maskAllInputs: true,
				blockAllMedia: false,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
		environment: process.env.NODE_ENV,
		release: process.env.REACT_APP_VERSION,
	});
};

// Compares script tags eg. 'main.cb6551.js' on the browser vs. what's on the server to
// check if an old UI version is cached
export const checkForVersionUpdates = async () => {
	const deploymentUrl = INDEX_HTML_DEPLOYMENT_URL;
	const SCRIPT_REGEX_TAGS = /<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g;
	const SCRIPT_REGEX_MAIN = /^.*\/(main.*\.js).*$/gim;

	const fetchedPage = await fetch(deploymentUrl, {
		method: 'get',
	});

	const loadedText = await fetchedPage.text();

	// get the main.js file to get hash
	const matchResponses = SCRIPT_REGEX_TAGS.exec(loadedText);

	let remoteMainScript =
		matchResponses?.length > 0 ? SCRIPT_REGEX_MAIN.exec(matchResponses[0]) : undefined;

	if (remoteMainScript[1] === undefined) {
		return false;
	}

	// get the current version hash from current deployment
	let currentMainScript = undefined;

	// get text representation of document
	const scriptTags = document.head.getElementsByTagName('script');
	for (let i = 0; i < scriptTags.length; i++) {
		const scriptTag = scriptTags[i];
		currentMainScript =
			/^.*\/(main.*\.js).*$/gim.exec(scriptTag.src) === null
				? undefined
				: /^.*\/(main.*\.js).*$/gim.exec(scriptTag.src)[1];
	}

	// if the current main script or the remote main script is undefined, we can't compare
	// if they are the same, compare them
	const status =
		!!currentMainScript && !!remoteMainScript[1] && currentMainScript !== remoteMainScript[1];

	store.dispatch({
		type: SET_NEW_VERSION_AVAILABLE,
		payload: status ?? false,
	});
};

