import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import { useTranslation } from 'react-i18next';

export default function SnackbarAndModalController() {
	const { t, i18n } = useTranslation();
	const machine = useSelector((state) => state.process.machine);
	const employeeToken = useSelector((state) => state.auth.employee.token);
	const kiosk = useSelector((state) => state.admin.kiosk);
	const express = useSelector((state) => state.admin.express);
	const setMaterials = useSelector((state) => state.admin.materialType);
	const language = useSelector((state) => state.auth.language);
	const machineLocation = useSelector((state) => state.admin.state);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarButton, setSnackbarButton] = useState(false);
	const [showClose, setShowClose] = useState(true);
	const [snackbarTitle, setSnackbarTitle] = useState('');

	useEffect(() => {
		// need to delay for intial app load
		setTimeout(() => {
			setMachineMessage();
		}, 1000);
	}, [
		machine.belt_id,
		machine.secret,
		language,
		setMaterials,
		machineLocation,
		express,
		kiosk,
		employeeToken,
	]);

	const closeSnackbar = () => {
		setSnackbarTitle('');
		setDuration(null);
		setSnackbarMessage('');
	};

	const handleShowMessage = (msg) => {
		setSnackbarButton(false);
		setShowClose(false);
		setSnackbarMessage(msg);
	};

	// machine related messages such as no belt id or no materials selected
	const setMachineMessage = () => {
		const machineSetup = machine.belt_id && machine.secret;
		const fullSignUpPage = window.location.toString().includes('full-sign-up');

		if (fullSignUpPage) return;

		if (!machineSetup && setMaterials.length) {
			handleShowMessage(t('messages.machineIssue.noBeltId'));
		} else if (!kiosk && !express) {
			handleShowMessage(t('messages.machineIssue.noModeEnabled'));
		} else if (machineSetup && !setMaterials.length) {
			handleShowMessage(t('messages.machineIssue.noMaterialType'));
		} else if (!setMaterials.length && !machineSetup) {
			handleShowMessage(t('messages.machineIssue.noBeltIdNoMaterialType'));
		} else if (!machineLocation) {
			handleShowMessage(t('messages.warning.noMachineLocation'));
		} else if (!employeeToken) {
			handleShowMessage(t('messages.warning.noEmployeeLogin'));
		} else {
			setSnackbarMessage('');
		}
	};

	return (
		<>
			<CustomSnackbar
				message={snackbarMessage}
				button={snackbarButton}
				showCloseButton={showClose}
				title={snackbarTitle}
				close={closeSnackbar}
			/>
		</>
	);
}
