import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// components
import CustomButton from 'components/UIKit/CustomButton';
import Totals from 'pages/Express/ReviewAndFinalize/Totals';
import TotalsFooter from 'pages/Express/ReviewAndFinalize/TotalsFooter';
import FinalTotalFooter from 'pages/Express/ReviewAndFinalize/FinalTotalFooter';
import Editor from 'pages/Express/ReviewAndFinalize/Editor';
import FinalTotal from 'pages/Express/ReviewAndFinalize/FinalTotal';
import { emailReceipt } from 'redux/actions/process';
import { cashPayout } from 'redux/actions/user';
import AnimatedCheckmark from 'components/UIKit/AnimatedCheckmark/AnimatedCheckmark';
import ScrollContainer from 'components/UIKit/ScrollContainer';
import CustomSpinner from 'components/UIKit/CustomSpinner/CustomSpinner';
import { SET_ECOCOUNT_SCRAP, SET_ADMIN_AUTH, SET_ECOCOUNT_SCRAP_TOTAL } from 'redux/actions/types';
import { employeeLogin } from 'redux/actions/auth';
import { getCustomerBalance } from 'redux/actions/user';
import { setLoading } from 'redux/actions/userInterface';
import { generalErrorModal } from 'redux/actions/modal';

// utils/lib
import {
	getTotalAdjustments,
	getRedeemableTotalContainers,
	getTotalEcoCountScrap,
} from 'utils/totalContainers';
import { bluetoothPrint } from 'utils/printer';
import { formatPrevScrapEntry } from 'utils/formatPrevScrapEntry';
import { DELAY_SHORT } from 'lib/constants';

const useStyles = makeStyles((theme) => ({
	main: {
		padding: '10px 20px',
		paddingBottom: '100px',
		height: '85vh',
		'& hr': {
			margin: '20px 0px',
		},
	},
	spinner: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	confirmText: {
		textAlign: 'center',
		marginTop: '100px',
	},
	footer: {
		backgroundColor: theme.palette.background.default,
		borderTop: `1px solid ${theme.palette.grey.main}`,
		position: 'absolute',
		bottom: '0px',
		padding: '10px 0px 20px 0px',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		zIndex: '300',
		'& button': {
			margin: '0px 20px',
		},
	},
	backConfirm: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		marginTop: '10%',
		padding: '20px',
		'& button': {
			margin: '30px 40px',
		},
	},
}));

export default function ExpressReviewAndFinalize({
	closeOverlay,
	grossWeights,
	deleteWeight,
	tareWeights,
	handleGrossWeight,
	handleTareWeight,
	calculateNetWeights,
	taskEnded,
	adjustCounts,
	password,
	setPassword,
	materials,
	end,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const emailReceiptEnabled = useSelector((state) => state.admin.isEmailReceiptOn);
	const redemptionAmount = useSelector((state) => state.process.data.redemption_amount_cents);
	const remotePrinter = useSelector((state) => state.admin.remotePrinter);
	const ecoCountScrap = useSelector((state) => state.process.data.scrap);
	const walletBalance = useSelector((state) => state.auth.wallet);
	const paymentType = useSelector((state) => state.auth.paymentType);
	const machineUrl = useSelector((state) => state.admin.url);
	const prevTaskId = useSelector((state) => state.process.prevTaskId);
	const language = useSelector((state) => state.auth.language);
	const employee = useSelector((state) => state.auth.employee);
	const loading = useSelector((state) => state.userInterface.loading);
	const scrap = useSelector((state) => state.adjustments.scrap);
	const [showCashoutConfirmation, setShowCashoutConfirmation] = useState(false);
	const [showScrapEcoCountTable, setShowScrapEcoCountTable] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [showEditEcoCountScrap, setShowEditEcoCountScrap] = useState(false);
	const [unformattedPrevEntry, setUnformattedPrevEntry] = useState({});
	const [resetEditsConfirm, setResetEditsConfirm] = useState(false);
	const [prevWeightEntry, setPrevWeightEntry] = useState({});
	const [showWeightTable, setShowWeightTable] = useState(true);
	const [showCountTable, setShowCountTable] = useState(true);
	const [showEditWeight, setShowEditWeight] = useState(false);
	const [materialToEdit, setMaterialToEdit] = useState('');
	const [prevScrapEntry, setPrevScrapEntry] = useState({});
	const [showResetEdits, setShowResetEdits] = useState(false);
	const [showEditCount, setShowEditCount] = useState(false);
	const [showEditScrap, setShowEditScrap] = useState(false);
	const [scrapEcoCount, setScrapEcoCount] = useState(0);
	const [isScrap, setIsScrap] = useState(false);
	const [weight, setWeight] = useState(0);
	const [count, setCount] = useState(0);
	const expressMode = window.location.toString().includes('express');
	const prevWalletBalance = walletBalance?.new_customer_balance;
	const newWalletBalance = prevWalletBalance
		? prevWalletBalance / 10000 + redemptionAmount
		: redemptionAmount;

	useEffect(() => {
		areThereScrapEntries();
		setEcoCountScrapStore();
	}, []);

	useEffect(() => {
		handleReturnToKiosk();
	}, [taskEnded, adjustCounts]);

	useEffect(() => {
		const adjustments = getTotalAdjustments() || 0;
		const ecoCountRes = getRedeemableTotalContainers();
		const ecoCount = ecoCountRes === 'N/A' ? 0 : ecoCountRes;

		setCount(adjustments + ecoCount);
	}, [showEditCount]);

	useEffect(() => {
		getNetWeights();
	}, [showEditWeight]);

	useEffect(() => {
		getScrapEcoCountTotal();
	}, [showEditEcoCountScrap]);

	useEffect(() => {
		!walletBalance && expressMode && dispatch(getCustomerBalance());
	}, [redemptionAmount]);

	const getScrapEcoCountTotal = () => {
		setScrapEcoCount(getTotalEcoCountScrap());
	};

	const getNetWeights = async () => {
		const netWeights = await calculateNetWeights(grossWeights, tareWeights);
		let total = 0;

		Object.keys(netWeights).forEach((weight) => {
			total = netWeights[weight] + total;
		});

		setWeight(total);
	};

	const areThereScrapEntries = () => {
		Object.keys(scrap).map((material) => {
			if (scrap[material].length) return setIsScrap(true);
		});
	};

	const setEcoCountScrapStore = () => {
		dispatch({
			type: SET_ECOCOUNT_SCRAP,
			payload: ecoCountScrap || {},
		});
	};

	const handleEmailReceipt = () => {
		emailReceiptEnabled && dispatch(emailReceipt());
		paymentType === 'cash' && dispatch(cashPayout());
		setIsButtonDisabled(false);
	};

	const digitalReceipt = () => {
		setIsButtonDisabled(true);
		handleEmailReceipt();
		history.push('/thank-you');
	};

	const printReceipt = () => {
		setIsButtonDisabled(true);
		handleEmailReceipt();
		remotePrinter && bluetoothPrint(prevTaskId);

		if (paymentType === 'cash' && remotePrinter) {
			setShowCashoutConfirmation(true);
		} else {
			history.push('/print-receipt');
		}
	};

	const handleShowCountTable = () => {
		setShowCountTable((current) => !current);
	};

	const handleShowWeightTable = () => {
		setShowWeightTable((current) => !current);
	};

	const handleShowScrapEcoCountTable = () => {
		setShowScrapEcoCountTable((current) => !current);
	};

	const editCount = (material) => {
		setShowEditCount(true);
		setMaterialToEdit(material);
	};

	const editWeight = (material) => {
		setPrevWeightEntry({
			gross: grossWeights[material],
			tare: tareWeights[material],
		});
		deleteWeight(material);
		setShowEditWeight(true);
		setMaterialToEdit(material);
	};

	const handleCloseEdit = () => {
		setShowEditCount(false);
		setShowEditWeight(false);
		setShowEditScrap(false);
		setShowEditEcoCountScrap(false);
		setMaterialToEdit('');
	};

	const editScrap = (material, entry) => {
		const formatted = formatPrevScrapEntry(entry);

		setPrevScrapEntry(formatted);
		setShowEditScrap(true);
		setMaterialToEdit(material);
	};

	const editEcoCountScrap = (material, entry) => {
		const formatted = formatPrevScrapEntry(entry);

		setShowResetEdits(true);
		setUnformattedPrevEntry(entry);
		setPrevScrapEntry(formatted);
		setShowEditEcoCountScrap(true);
		setMaterialToEdit(material);
		getScrapEcoCountTotal();
	};

	const handleCloseOverlay = () => {
		if (showResetEdits) {
			setResetEditsConfirm(true);
		} else {
			closeOverlay();
		}
	};

	const handleReturnToKiosk = () => {
		if (adjustCounts && taskEnded) {
			dispatch({
				type: SET_ECOCOUNT_SCRAP_TOTAL,
				payload: scrapEcoCount,
			});
			setTimeout(() => {
				dispatch({
					type: SET_ADMIN_AUTH,
					payload: false,
				});
				history.push('/checkout');
			}, DELAY_SHORT);
		}
	};

	const confirmCashout = () => {
		setShowCashoutConfirmation(false);
		history.push('/thank-you');
	};

	const verifyEmployee = () => {
		dispatch(setLoading(true));
		dispatch(employeeLogin(employee.username, password))
			.then(() => {
				end();
			})
			.catch(() => {
				dispatch(generalErrorModal('Incorrect credentials. Please try again.'));
			});
	};

	return (
		<>
			{!taskEnded &&
				!showEditCount &&
				!showEditWeight &&
				!showEditScrap &&
				!showEditEcoCountScrap &&
				!resetEditsConfirm && (
					<>
						<Box className={classes.main}>
							{loading && (
								<Box className={classes.spinner}>
									<CustomSpinner />
								</Box>
							)}
							{!loading && (
								<ScrollContainer>
									<Totals
										count={count}
										weight={weight}
										isScrap={isScrap}
										tareWeights={tareWeights}
										grossWeights={grossWeights}
										deleteWeight={deleteWeight}
										scrapEcoCount={scrapEcoCount}
										showCountTable={showCountTable}
										showWeightTable={showWeightTable}
										showScrapEcoCountTable={showScrapEcoCountTable}
										editCount={editCount}
										editScrap={editScrap}
										editWeight={editWeight}
										editEcoCountScrap={editEcoCountScrap}
										handleShowCountTable={handleShowCountTable}
										getScrapEcoCountTotal={getScrapEcoCountTotal}
										handleShowWeightTable={handleShowWeightTable}
										handleShowScrapEcoCountTable={handleShowScrapEcoCountTable}
									/>
								</ScrollContainer>
							)}
						</Box>
						<TotalsFooter
							password={password}
							setPassword={setPassword}
							handleCloseOverlay={handleCloseOverlay}
							verifyEmployee={verifyEmployee}
						/>
					</>
				)}
			{taskEnded &&
				!showEditCount &&
				!showEditWeight &&
				!showEditScrap &&
				!showEditEcoCountScrap &&
				!adjustCounts &&
				!showCashoutConfirmation && (
					<Box className={classes.main}>
						<FinalTotal
							redemptionAmount={redemptionAmount}
							language={language}
							paymentType={paymentType}
							newWalletBalance={newWalletBalance}
						/>
						<FinalTotalFooter
							machineUrl={machineUrl}
							remotePrinter={remotePrinter}
							isButtonDisabled={isButtonDisabled}
							digitalReceipt={digitalReceipt}
							printReceipt={printReceipt}
						/>
					</Box>
				)}
			{taskEnded && adjustCounts && (
				<>
					<Box className={classes.confirmText}>
						<Typography variant='h6'>{t('overlay.review.adjustmentsSubmitted')}</Typography>
					</Box>
					<AnimatedCheckmark />
				</>
			)}
			{showCashoutConfirmation && (
				<>
					<Box className={classes.confirmText}>
						<Typography variant='h4'>{t('overlay.review.pleaseCashoutCustomer')}</Typography>
					</Box>
					<Box className={classes.footer}>
						<CustomButton onClick={confirmCashout}>
							{t('common.buttons.continue')}
						</CustomButton>
					</Box>
				</>
			)}
			{(showEditCount || showEditWeight || showEditScrap || showEditEcoCountScrap) && (
				<Editor
					showEditEcoCountScrap={showEditEcoCountScrap}
					unformattedPrevEntry={unformattedPrevEntry}
					handleGrossWeight={handleGrossWeight}
					handleTareWeight={handleTareWeight}
					prevWeightEntry={prevWeightEntry}
					showEditWeight={showEditWeight}
					materialToEdit={materialToEdit}
					prevScrapEntry={prevScrapEntry}
					showEditScrap={showEditScrap}
					showEditCount={showEditCount}
					grossWeights={grossWeights}
					tareWeights={tareWeights}
					materials={materials}
					handleCloseEdit={handleCloseEdit}
				/>
			)}
			{resetEditsConfirm && (
				<Box className={classes.backConfirm}>
					<Typography>{t('overlay.review.areYouSureEcoCountScrap')}</Typography>
					<Box className={classes.buttonGroup}>
						<CustomButton variant='alternate' onClick={() => setResetEditsConfirm(false)}>
							{t('overlay.no')}
						</CustomButton>
						<CustomButton variant='danger' onClick={() => closeOverlay()}>
							{t('overlay.yes')}
						</CustomButton>
					</Box>
				</Box>
			)}
		</>
	);
}
