import { useEffect, useState } from 'react';

const SCRIPT_REGEX_TAGS = /<script\b[^>]*>[\s\S]*?<\/script\b[^>]*>/g;
const SCRIPT_REGEX_MAIN = /^.*\/(main.*\.js).*$/gim;

export const useVersionPoller = ({ deploymentUrl }) => {
	const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
	useEffect(() => {
		const compareVersions = async () => {
			const fetchedPage = await fetch(deploymentUrl, {
				method: 'get',
			});

			// get the text from the response
			const loadedText = await fetchedPage.text();

			// get the main.js file to get hash
			const matchResponses = SCRIPT_REGEX_TAGS.exec(loadedText);

			let remoteMainScript =
				matchResponses?.length > 0 ? SCRIPT_REGEX_MAIN.exec(matchResponses[0]) : undefined;

			if (remoteMainScript === undefined) {
				setIsNewVersionAvailable(false);
				return;
			}

			// get the current version hash from current deployment
			let currentMainScript = undefined;

			// get text representation of document
			const scriptTags = document.head.getElementsByTagName('script');
			for (let i = 0; i < scriptTags.length; i++) {
				const scriptTag = scriptTags[i];
				currentMainScript =
					/^.*\/(main.*\.js).*$/gim.exec(scriptTag.src) === null
						? undefined
						: /^.*\/(main.*\.js).*$/gim.exec(scriptTag.src)[1];
			}

			// if the current main script or the remote main script is undefined, we can't compare
			// but if they are there, compare them
			setIsNewVersionAvailable(
				!!currentMainScript && !!remoteMainScript[1] && currentMainScript !== remoteMainScript[1],
			);
		};

		// compare versions every hour
		const createdInterval = setInterval(compareVersions, 3600000); // 3600000 = 1 hour
		return () => {
			// clear the interval when the component unmounts
			clearInterval(createdInterval);
		};
	}, [deploymentUrl]);

	// return the state
	return { isNewVersionAvailable };
};
