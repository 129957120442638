import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';

// components
import PortalTextField from 'components/UIKit/PortalTextField';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '350px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .MuiTypography-root': {
			marginTop: '8px',
		},
		[theme.breakpoints.down('md')]: {
			width: '320px',
		},
	},
	input: {
		maxWidth: '200px',
		height: '66px',
		maxHeight: '66px',
		align: 'left',
		'& .MuiInputBase-input': {
			color: theme.palette.text.primary,
		},
	},
	password: {
		'& input': {
			'-webkit-text-security': 'disc',
		},
	},
	select: {
		'& .MuiSelect-root': {
			width: '100px',
		},
		margin: '6px auto',
	},
	switch: {
		marginTop: '12px',
		marginBottom: '22px',

		'& .MuiSwitch-root': {
			background: theme.palette.secondary.main,
			borderRadius: '8px',
			width: '64px',
			marginTop: '10px',
		},
		'& .MuiSwitch-colorSecondary.Mui-checked': {
			color: '#97D700',
		},
		'& .Mui-checked.Mui-checked + .MuiSwitch-track': {
			backgroundColor: theme.palette.tertiary.main,
		},
	},
	secondary: {
		width: 'unset',
	},
	unavailable: {
		opacity: '0.5',
	},
}));

export default function AdminInput({
	name,
	variant,
	label,
	value,
	isPassword,
	disabled,
	input,
	handleChange,
	keyDown,
	type,
	checked,
	autoFocus,
	selectValue,
	selectData,
	unavailable,
}) {
	const classes = useStyles();
	const uniqueId = uuid().slice(0, 8);
	const [showPasswordText, setShowPasswordText] = useState(false);

	const container = clsx({
		[classes.container]: true,
		[classes.password]: isPassword && !showPasswordText,
		[classes.secondary]: variant === 'secondary',
		[classes.switch]: type === 'switch',
		[classes.unavailable]: unavailable,
	});

	const handlePasswordVisibility = () => {
		setShowPasswordText(!showPasswordText);
	};

	return (
		<Box className={container}>
			<Typography variant='body2'>{label}:</Typography>
			{type === 'switch' && (
				<Switch value={value} checked={checked} disabled={unavailable} onChange={handleChange} />
			)}
			{type === 'select' && (
				<Select className={classes.select} value={selectValue} onChange={handleChange}>
					{selectData &&
						selectData.map((item) => <MenuItem value={item.value}>{item.text}</MenuItem>)}
					{!selectData && <MenuItem>No Data to Display</MenuItem>}
				</Select>
			)}
			{!type && (
				<PortalTextField
					className={classes.input}
					id={uniqueId}
					value={value}
					fullWidth
					name={name}
					type={input}
					disabled={disabled}
					handlePasswordVisibility={handlePasswordVisibility}
					onChange={handleChange}
					onKeyDown={keyDown}
					passwordInput={isPassword}
					isPasswordVisible={showPasswordText}
					autoFocus={autoFocus}
				/>
			)}
		</Box>
	);
}
