import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomButton from 'components/UIKit/CustomButton';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import RemoveIcon from '@material-ui/icons/Remove';
import {
	ADJUST_COUNT_PETE,
	ADJUST_COUNT_ALU,
	ADJUST_COUNT_GLS,
	ADJUST_COUNT_BMT,
	ADJUST_COUNT_HDPE,
	ADJUST_COUNT_LDPE,
	ADJUST_COUNT_PP,
	ADJUST_COUNT_PS,
	ADJUST_COUNT_OTHER,
	ADJUST_COUNT_BIB,
	ADJUST_COUNT_MLP,
	ADJUST_COUNT_PBC,
	ADJUST_COUNT_PVC,
} from 'redux/actions/types';

const useStyles = makeStyles((theme) => ({
	section: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	main: {
		display: 'flex',
		[theme.breakpoints.down('lg')]: {
			flexDirection: 'column',
		},
	},
	container: {
		border: `1px solid ${theme.palette.grey.main}`,
		borderRadius: '10px',
		margin: '10px 10px 20px 0px',
		width: '370px',
		'& h4': {
			marginLeft: '30px',
		},
		'& h6': {
			marginLeft: '20px',
		},
		[theme.breakpoints.down('lg')]: {
			margin: '10px 0px 0px 0px',
			width: '330px',
		},
	},
	large: {
		marginLeft: '34px',
	},
	size: {
		fontWeight: '600',
		marginLeft: '20px',
	},
	labelContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	sectionTop: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		borderBottom: `1px solid ${theme.palette.grey.main}`,
		padding: '4px',
		'& .MuiDivider-root': {
			backgroundColor: theme.palette.grey.main,
			height: '50px',
			margin: 'auto 10px',
		},
	},
	ecoCount: {
		opacity: '0.7',
	},
	sectionBottom: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4px',
	},
	increment: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& button': {
			marginTop: '26px',
			paddingTop: '10px',
		},
		[theme.breakpoints.down('lg')]: {
			flexDirection: 'row-reverse',
			justifyContent: 'space-evenly',
		},
	},
	badge: {
		borderRadius: '50px',
		padding: '3px 14px',
		display: 'flex',
		alignItems: 'center',
		color: '#FFFFFF',
		marginLeft: '10px',
	},
	labelSmall: {
		backgroundColor: theme.palette.primary.dark,
	},
	labelLarge: {
		backgroundColor: theme.palette.secondary.light,
	},
}));

// TODO: input background color needs to be inherit, text needs to become white and enlarged

export default function Count({
	material,
	ecoCount,
	adjustment,
	type,
	disabled,
	available,
	hidden,
	displayName,
}) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const intervalRef = useRef(null);
	const adjustments = useSelector((state) => state.adjustments.materials);
	const [incrementCounter, setIncrementCounter] = useState(0);
	const [decrementCounter, setDecrementCounter] = useState(0);

	const badges = clsx({
		[classes.badge]: true,
		[classes.labelSmall]: type === 'small',
		[classes.labelLarge]: type === 'large',
	});

	const container = clsx({
		[classes.container]: true,
		[classes.large]: type === 'large',
	});

	const types = {
		['PETE']: ADJUST_COUNT_PETE,
		['ALU']: ADJUST_COUNT_ALU,
		['GLS']: ADJUST_COUNT_GLS,
		['BMT']: ADJUST_COUNT_BMT,
		['HDPE']: ADJUST_COUNT_HDPE,
		['PVC']: ADJUST_COUNT_PVC,
		['LDPE']: ADJUST_COUNT_LDPE,
		['PP']: ADJUST_COUNT_PP,
		['PS']: ADJUST_COUNT_PS,
		['BIB']: ADJUST_COUNT_BIB,
		['MLP']: ADJUST_COUNT_MLP,
		['PBC']: ADJUST_COUNT_PBC,
		['OTHER']: ADJUST_COUNT_OTHER,
	};

	useEffect(() => {
		return () => stopCounter(); // stop/clear counter on unmount
	}, []);

	useEffect(() => {
		incrementCounter > 1 && handleAdjustment(10, 'add');
	}, [incrementCounter]);

	useEffect(() => {
		decrementCounter > 1 && handleAdjustment(10, 'subtract');
	}, [decrementCounter]);

	// sizing category label
	const getSizeLabel = () => {
		const small = type === 'small';
		const large = type === 'large';

		if (small) {
			return '< 24oz';
		} else if (large) {
			return '≥ 24oz';
		} else if (displayName) {
			return displayName;
		}
	};

	// the total of the EcoCount and the adjustment
	const getTotal = () => {
		return ecoCount ? ecoCount + adjustment : adjustment;
	};

	// increases or decreases the adjustment count
	const handleAdjustment = (value, operation) => {
		const materialSmall = adjustments[material].sm;
		const materialLarge = adjustments[material].lg;
		const decrementValue = getTotal() > 10 ? value : 1;
		const large = type === 'large';
		const small = type === 'small';
		const add = operation === 'add';
		const action = types[material];
		const newAdjustment = add ? adjustment + value : adjustment - decrementValue;
		const payloadSm = { sm: newAdjustment, lg: materialLarge };
		const payloadLg = { sm: materialSmall, lg: newAdjustment };

		switch (material) {
			case 'PETE':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'ALU':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'GLS':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'BMT':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'HDPE':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'PVC':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'LDPE':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'PP':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'PS':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'OTHER':
				small && dispatch({ type: action, payload: payloadSm });
				large && dispatch({ type: action, payload: payloadLg });
				break;
			case 'BIB':
				dispatch({ type: action, payload: payloadLg }); // only one size (defaults as md)
				break;
			case 'MLP':
				dispatch({ type: action, payload: payloadLg }); // only one size (defaults as md)
				break;
			case 'PBC':
				dispatch({ type: action, payload: payloadLg }); // only one size (defaults as md)
		}
	};

	// increment or decrement adjusted counts by 1 and start a timer to determine if button is being held down
	const adjustAndStartCounter = (operation) => {
		handleAdjustment(1, operation);
		if (intervalRef.current) return;
		intervalRef.current = setInterval(() => {
			operation === 'add' && setIncrementCounter((prevCounter) => prevCounter + 1);
			operation === 'subtract' && setDecrementCounter((prevCounter) => prevCounter + 1);
		}, 400);
	};

	// stop timer when button stops being held down
	const stopCounter = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	return (
		<>
			{!hidden && (
				<Box className={classes.main}>
					<Box className={classes.section}>
						<Box className={classes.labelContainer}>
							<Typography className={classes.size}>{getSizeLabel()}</Typography>
							<Box className={badges}>
								<Typography variant='caption'>{type}</Typography>
							</Box>
						</Box>
						<Box className={container}>
							<Box className={classes.sectionTop}>
								<Typography className={classes.ecoCount}>Eco Count</Typography>
								<Typography className={classes.ecoCount} variant='h6'>
									{available ? ecoCount || 0 : 'N/A'}
								</Typography>
								<Divider orientation='vertical' />
								<Typography>{t('materialPanel.adjust')}</Typography>
								<Typography variant='h6'>{adjustment}</Typography>
							</Box>
							<Box className={classes.sectionBottom}>
								<Typography>{t('materialPanel.total')}</Typography>
								<Typography variant='h4'>{getTotal()}</Typography>
							</Box>
						</Box>
					</Box>
					<Box className={classes.increment}>
						<CustomButton
							disabled={disabled}
							name='cy-increment'
							size='extra-small'
							onPointerDown={() => adjustAndStartCounter('add')}
							onPointerUp={stopCounter}
							onPointerLeave={stopCounter}
						>
							<AddIcon fontSize='large' />
						</CustomButton>
						<CustomButton
							disabled={disabled || getTotal() <= 0}
							name='cy-decrement'
							size='extra-small'
							onPointerDown={() => adjustAndStartCounter('subtract')}
							onPointerUp={stopCounter}
							onPointerLeave={stopCounter}
						>
							<RemoveIcon fontSize='large' />
						</CustomButton>
					</Box>
				</Box>
			)}
		</>
	);
}
