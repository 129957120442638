import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Count from 'components/Express/Count'; // TODO: Move the count component into this folder

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export default function CountInputs({ material, show }) {
	const classes = useStyles();
	const adjustments = useSelector((state) => state.adjustments.materials);

	return (
		<>
			{show && (
				<Box className={classes.container}>
					<Count
						type='small'
						hidden={material.oneSize}
						material={material.type}
						ecoCount={material.sm.ecoCount}
						available={material.sm.available}
						adjustment={adjustments[material.type].sm}
					/>
					<Count
						type={material.oneSize ? '' : 'large'}
						material={material.type}
						ecoCount={material.lg.ecoCount}
						available={material.lg.available}
						adjustment={adjustments[material.type].lg}
					/>
				</Box>
			)}
		</>
	);
}
