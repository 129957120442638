import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useVersionPoller } from './useVersionPoller';
import { SET_NEW_VERSION_AVAILABLE } from 'redux/actions/types';
import { INDEX_HTML_DEPLOYMENT_URL } from 'lib/urls';

function VersionUpdates() {
	const dispatch = useDispatch();
	const { isNewVersionAvailable } = useVersionPoller({ deploymentUrl: INDEX_HTML_DEPLOYMENT_URL });

	useEffect(() => {
		if (isNewVersionAvailable) {
			dispatch({
				type: SET_NEW_VERSION_AVAILABLE,
				payload: true,
			});
		} else {
			dispatch({
				type: SET_NEW_VERSION_AVAILABLE,
				payload: false,
			});
		}
	}, [isNewVersionAvailable]);
}

export default VersionUpdates;
