import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import MachineMessages from 'components/Alerts/MachineMessages';
import { isDevelopmentEnv } from 'utils/environment';
import { useTranslation } from 'react-i18next';
import { DELAY_MID } from 'lib/constants';

export default function SnackbarAndModalController() {
	const { t, i18n } = useTranslation();
	const dailyWeightLimits = useSelector((state) => state.process.dailyWeightLimits);
	const taskId = useSelector((state) => state.process.task_id?.task_id);
	const dryrun = useSelector((state) => state.process.dryrun);
	const language = useSelector((state) => state.auth.language);
	const snackbar = useSelector((state) => state.userInterface.snackbar);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarButton, setSnackbarButton] = useState(false);
	const [showClose, setShowClose] = useState(true);
	const [duration, setDuration] = useState(null);
	const [snackbarTitle, setSnackbarTitle] = useState('');
	const [status, setStatus] = useState('');

	useEffect(() => {
		setSnackbarMessage(snackbar.message);
		setStatus(snackbar.status);
		setDuration(DELAY_MID);
	}, [snackbar]);

	useEffect(() => {
		setDryrunMessage();
	}, [dryrun]);

	useEffect(() => {
		checkWeightLimits();
	}, [dailyWeightLimits]);

	const closeSnackbar = () => {
		setSnackbarTitle('');
		setDuration(null);
		setSnackbarMessage('');
	};

	const checkWeightLimits = () => {
		const withinTenPercent = dailyWeightLimits.withinTenPercent;
		const overLimit = dailyWeightLimits.overweight;
		const warningOnly = withinTenPercent.length && !overLimit.length;
		const typesNearLimit = withinTenPercent.toString().split(',').join(', ');
		const typesOverLimit = overLimit.toString().split(',').join(', ');
		const nearLimitText = `${t('messages.weightLimits.almostOverweight')} ${
			typesNearLimit || t('messages.weightLimits.none')
		}`;
		const overLimitText = `${t('messages.weightLimits.overweight')} ${
			typesOverLimit || t('messages.weightLimits.none')
		}`;

		if (taskId && (overLimit.length || withinTenPercent.length)) {
			setStatus(warningOnly ? 'warning' : 'error');
			setDuration(90000);
			setShowClose(false);
			setSnackbarTitle(overLimitText);
			setSnackbarMessage(nearLimitText);
		}

		if (!overLimit.length && !withinTenPercent.length) {
			closeSnackbar(); // close the snackbar when the store is cleared aka user logs out
		}
	};

	// dryrun related messages
	const setDryrunMessage = () => {
		if (!dryrun && isDevelopmentEnv()) {
			setShowClose(true);
			setSnackbarMessage(t('messages.warning.dryrunOff'));
			setDuration(DELAY_MID);
		}
	};

	return (
		<>
			<CustomSnackbar
				message={snackbarMessage}
				button={snackbarButton}
				autoHideDuration={duration}
				showCloseButton={showClose}
				status={status}
				title={snackbarTitle}
				close={closeSnackbar}
			/>
			<MachineMessages />
		</>
	);
}
