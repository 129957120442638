// lib
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import RedemptionTotals from '../../components/CountsAndWeight/RedemptionTotals.js';
import phoneGif from 'assets/images/phoneGifReduced.gif';

// mui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: '40px',
		'& .MuiTypography-root': {
			fontSize: '24px',
		},
	},
	image: {
		height: '340px',
	},
}));

export default function CheckoutMain({ finalizedBalance, options, paymentType }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<>
			{!finalizedBalance && (
				<Box className={classes.container}>
					<img src={phoneGif} className={classes.image} alt='loading image' />
					<Typography variant='h6'>{t('checkout.finalizingBalance')}</Typography>
				</Box>
			)}
			{finalizedBalance && <RedemptionTotals type={paymentType} options={options} />}
		</>
	);
}
