// lib
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// components
import { employeeLogin } from 'redux/actions/auth';
import CustomErrorBadge from 'components/UIKit/CustomErrorBadge';
import CustomButton from 'components/UIKit/CustomButton';
import AdminMessages from 'components/Admin/AdminMessages';
import PageHeader from 'components/Admin/PageHeader';
import AdminInput from 'components/Admin/AdminInput';
import Section from 'components/Admin/Section';

// utils
import { isDevelopmentEnv } from 'utils/environment';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		margin: '30px auto 0px auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& .MuiInputBase-root': {
			width: '380px',
		},
	},
	buttonContainer: {
		marginTop: '20px',
		display: 'flex',
		justifyContent: 'center',
	},
	messages: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
}));

export default function AdminPassword() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const prevLocation = useSelector((state) => state.admin.prevLocation);
	const materialType = useSelector((state) => state.admin.materialType);
	const employee = useSelector((state) => state.auth.employee);
	const machine = useSelector((state) => state.process.machine);
	const customerLoggedIn = useSelector((state) => state.auth.isAuthenticated);
	const language = useSelector((state) => state.auth.language);
	const [password, setPassword] = useState('');
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [username, setUsername] = useState(
		isDevelopmentEnv() ? 'tenant_employee_recop@mail.com' : employee.username,
	);

	// quick scan of machine state to see if there are any basic errors (built to scale in future)
	const isMachineError = () => {
		const possibleErrors = {
			beltIdError: machine.belt_id === '',
			beltSecretError: machine.secret === '',
			materialTypeError: materialType === '',
		};
		const errors = Object.values(possibleErrors).some((value) => value === true);

		return errors;
	};

	// redirects user to a specific admin page
	const redirectUser = () => {
		const fromLogin = prevLocation === ('/sign-up' || '/sign-in');

		if (isMachineError()) {
			return history.push('/admin/settings');
		} else if (!customerLoggedIn) {
			return history.push('/admin/settings');
		} else if (fromLogin) {
			return history.push('/admin/settings');
		} else if (!fromLogin) {
			return history.push({
				pathname: '/admin/adjust-counts',
				state: password,
			});
		} else {
			history.push('/admin');
		}
	};

	const handleEmployeeLogin = () => {
		if (password && username) {
			setIsButtonDisabled(true);
			dispatch(employeeLogin(username, password))
				.then(() => {
					redirectUser();
				})
				.catch((err) => {
					setErrMsg(t('admin.couldNotLogin'), err);
				})
				.finally(() => {
					setIsButtonDisabled(false);
				});
		} else if (!password || !beltId) {
			setErrMsg(t('admin.fillOutAllFields'));
		}
	};

	// TODO: call admin auth and handle response
	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			handleEmployeeLogin();
		}
	};

	return (
		<>
			<PageHeader title={t('admin.adminLogin')} />
			<Box className={classes.container}>
				<Section title={t('admin.employeeLogin')} expanded>
					<AdminInput
						label={t('admin.email')}
						value={username}
						handleChange={(e) => setUsername(e.target.value)}
					/>
					<AdminInput
						label={t('admin.password')}
						value={password}
						isPassword={true}
						handleChange={(e) => setPassword(e.target.value)}
						keyDown={handleKeyDown}
					/>
					<Box className={classes.buttonContainer}>
						<CustomButton
							variant='secondary'
							size='small'
							disabled={isButtonDisabled}
							onClick={handleEmployeeLogin}
						>
							{t('admin.login')}
						</CustomButton>
					</Box>
				</Section>
				<CustomErrorBadge errorMessage={errMsg} />
			</Box>
			<Box className={classes.messages}>
				<AdminMessages />
			</Box>
		</>
	);
}
