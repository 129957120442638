// AUTH TYPES export const
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_GUEST_EMAIL = 'SET_GUEST_EMAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOADING = 'USER_LOADING';
export const LOAD_USER_FROM_STORAGE = 'LOAD_USER_FROM_STORAGE';
export const GUEST_LOGIN = 'GUEST_LOGIN';
export const SET_NEW_SIGNUP = 'SET_NEW_SIGNUP';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
export const SET_RECEIVED_BAGS = 'SET_RECEIVED_BAGS';
export const TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH';
export const RESET_TWO_FACTOR_AUTH = 'RESET_TWO_FACTOR_AUTH';

// CUSTOMER types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_CUSTOMER = 'REGISTER_CUSTOMER';
export const UNIQUE_CUSTOMER = 'UNIQUE_CUSTOMER';
export const NOT_UNIQUE_CUSTOMER = 'NOT_UNIQUE_CUSTOMER';
export const WALLET_BALANCE = 'WALLET_BALANCE';
export const SET_PAYMENT_ID = 'SET_PAYMENT_ID';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const SET_COMPLIANCE_INFO = 'SET_COMPLIANCE_INFO';
export const SET_SIGNATURE = 'SET_SIGNATURE';
export const SET_COMPLIANCE = 'SET_COMPLIANCE';

// PROCESS TYPES
export const RESUME_TASK = 'RESUME_TASK';
export const PRIME_SUCESS = 'PRIME_SUCESS';
export const SET_DETECTED = 'SET_DETECTED';
export const TASK_GENERATED = 'TASK_GENERATED';
export const RESET_TASK = 'RESET_TASK';
export const GET_TASK_STATS = 'GET_TASK_STATS';
export const TASK_END = 'TASK_END';
export const SET_BELT_ID = 'SET_BELT_ID';
export const SET_SECRET = 'SET_SECRET';
export const SET_PROCESS_COUNTS = 'SET_PROCESS_COUNTS';
export const SET_CONTAINER_TYPE = 'SET_CONTAINER_TYPE';
export const SET_MATERIAL_TO_LOAD = 'SET_MATERIAL_TO_LOAD';
export const SET_PREV_TASK_ID = 'SET_PREV_TASK_ID';
export const RESET_PROCESS_STATE = 'RESET_PROCESS_STATE';
export const SET_READY_TO_STOP = 'SET_READY_TO_STOP';
export const SET_REDEMPTION_AMOUNT = 'SET_REDEMPTION_AMOUNT';
export const SET_DAILY_WEIGHT_LIMITS = 'SET_DAILY_WEIGHT_LIMITS';
export const TRANSACTION_FINALIZED = 'TRANSACTION_FINALIZED';
export const SET_CURRENT_MATERIALS = 'SET_CURRENT_MATERIALS';

// ADJUSTMENT TYPES
export const ADJUST_COUNT_PETE = 'ADJUST_COUNT_PETE';
export const ADJUST_COUNT_ALU = 'ADJUST_COUNT_ALU';
export const ADJUST_COUNT_GLS = 'ADJUST_COUNT_GLS';
export const ADJUST_COUNT_BMT = 'ADJUST_COUNT_BMT';
export const ADJUST_COUNT_HDPE = 'ADJUST_COUNT_HDPE';
export const ADJUST_COUNT_LDPE = 'ADJUST_COUNT_LDPE';
export const ADJUST_COUNT_PP = 'ADJUST_COUNT_PP';
export const ADJUST_COUNT_PS = 'ADJUST_COUNT_PS';
export const ADJUST_COUNT_BIB = 'ADJUST_COUNT_BIB';
export const ADJUST_COUNT_MLP = 'ADJUST_COUNT_MLP';
export const ADJUST_COUNT_PBC = 'ADJUST_COUNT_PBC';
export const ADJUST_COUNT_OTH = 'ADJUST_COUNT_OTH';
export const ADJUST_COUNT_PVC = 'ADJUST_COUNT_PVC';
export const ADJUST_COUNT_OTHER = 'ADJUST_COUNT_OTHER'; 
export const ADD_SCRAP_ITEM = 'ADD_SCRAP_ITEM';
export const DELETE_SCRAP_ITEM = 'DELETE_SCRAP_ITEM';
export const ADD_ECOCOUNT_SCRAP_ITEM = 'ADD_ECOCOUNT_SCRAP_ITEM';
export const SET_ECOCOUNT_SCRAP_TOTAL = 'SET_ECOCOUNT_SCRAP_TOTAL';
export const DELETE_ECOCOUNT_SCRAP_ITEM = 'DELETE_ECOCOUNT_SCRAP_ITEM';
export const RESET_ECOCOUNT_ADJUSTMENTS = 'RESET_ECOCOUNT_ADJUSTMENTS';
export const ADD_WEIGHT_ITEM = 'ADD_WEIGHT_ITEM';
export const RESET_ADJUSTMENTS_STATE = 'RESET_ADJUSTMENTS_STATE';
export const SET_ECOCOUNT_SCRAP = 'SET_ECOCOUNT_SCRAP';

// STEPPER types
export const NEXT_STEP = 'NEXT_STEP';
export const PREVIOUS_STEP = 'PERVIOUS_STEP';
export const RESET_STEPS = 'RESET_STEPS';

// DRYRUN type
export const SET_DRYRUN = 'SET_DRYRUN';
export const SET_THROW_ERROR = 'SET_THROW_ERROR';

// NOTIFICATION MODAL types
export const MODAL_LOGIN_ERROR = 'MODAL_LOGIN_ERROR';
export const MODAL_LOGOUT = 'MODAL_LOGOUT';
export const MODAL_RESET = 'MODAL_RESET';
export const MODAL_ECO_CONFIRM = 'MODAL_ECO_CONFIRM';
export const MODAL_CASH_CONFIRM = 'MODAL_CASH_CONFIRM';
export const MODAL_CHARITY_CONFIRM = 'MODAL_CHARITY_CONFIRM';
export const MODAL_PRINT_RECEIPT = 'MODAL_PRINT_RECEIPT';
export const MODAL_INACTIVITY = 'MODAL_INACTIVITY';
export const MODAL_EMERGENCY_STOP = 'MODAL_EMERGENCY_STOP';
export const MODAL_EMERGENCY_STOP_CONFIRM = 'MODAL_EMERGENCY_STOP_CONFIRM';
export const MODAL_EMERGENCY_STOP_FAILED = 'MODAL_EMERGENCY_STOP_FAILED';
export const MODAL_START_ERROR = 'MODAL_START_ERROR';
export const MODAL_NO_ITEMS_DETECTED = 'MODAL_NO_ITEMS_DETECTED';
export const MODAL_PRINT_RECEIPT_ERROR = 'MODAL_PRINT_RECEIPT_ERROR';
export const MODAL_ALERT_OPERATOR = 'MODAL_ALERT_OPERATOR';
export const MODAL_CONFIRM_NO_ADJUSTMENT = 'MODAL_CONFIRM_NO_ADJUSTMENT';
export const MODAL_GENERAL_ERROR = 'MODAL_GENERAL_ERROR';
export const MODAL_ECOCARD_SETUP_SUCCESS = 'MODAL_ECOCARD_SETUP_SUCCESS';
export const MODAL_SELECT_MATERIAL_TO_RUN = 'MODAL_SELECT_MATERIAL_TO_RUN';
export const MODAL_OTHER_MATERIALS_SELECTED = 'MODAL_OTHER_MATERIALS_SELECTED';
export const MODAL_ESTOP_PRESSED = 'MODAL_ESTOP_PRESSED';

// METRICS TYPES
export const SET_METRICS = 'SET_METRICS';
export const RESET_METRICS = 'RESET_METRICS';

// ADMIN TYPES
export const SET_ADMIN_AUTH = 'SET_ADMIN_AUTH';
export const SET_DAILY_STATS = 'SET_DAILY_STATS';
export const SET_USER_FLOW_LOCATION = 'SET_USER_FLOW_LOCATION';
export const RESET_ADMIN = 'RESET_ADMIN';
export const SET_MATERIAL_TYPE = 'SET_MATERIAL_TYPE';
export const SET_EXPRESS_MODE = 'SET_EXPRESS_MODE';
export const SET_VIEW_ECOCARD_SETUP = 'SET_VIEW_ECOCARD_SETUP';
export const SET_USA_STATE = 'SET_USA_STATE';
export const SET_ECOCARD_SETUP_ENABLED = 'SET_ECOCARD_SETUP_ENABLED';
export const SET_IS_EMAIL_RECEIPT_ON = 'SET_IS_EMAIL_RECEIPT_ON';
export const SET_SOFTWARE_VERSION = 'SET_SOFTWARE_VERSION';
export const SET_DEMO_MODE = 'SET_DEMO_MODE';
export const SET_REMOTE_PRINTER = 'SET_REMOTE_PRINTER';
export const SET_EXPRESS_CASH_ENABLED = 'SET_EXPRESS_CASH_ENABLED';
export const SET_KIOSK_CASH_ENABLED = 'SET_KIOSK_CASH_ENABLED';
export const SET_NETWORK_SPEED = 'SET_NETWORK_SPEED';
export const SET_KIOSK_MODE = 'SET_KIOSK_MODE';
export const SET_MACHINE_DATA = 'SET_MACHINE_DATA';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const SET_ASSOCIATED_REDEMPTION_CENTER = 'SET_ASSOCIATED_REDEMPTION_CENTER';
export const SET_GUEST_LOGIN = 'SET_GUEST_LOGIN';
export const SET_KIOSK_DONATIONS_ENABLED = 'SET_KIOSK_DONATIONS_ENABLED';

// USER INTERFACE types
export const RESET_INTERFACE_STATE = 'RESET_INTERFACE_STATE';
export const SET_LOADING = 'SET_LOADING';
export const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE';
export const CLEAR_SNACKBAR_MESSAGE = 'CLEAR_SNACKBAR_MESSAGE';
export const SET_NEW_VERSION_AVAILABLE = 'SET_NEW_VERSION_AVAILABLE';