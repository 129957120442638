import {
	SET_DAILY_STATS,
	SET_ADMIN_AUTH,
	SET_USER_FLOW_LOCATION,
	RESET_ADMIN,
	SET_MATERIAL_TYPE,
	SET_EXPRESS_MODE,
	SET_VIEW_ECOCARD_SETUP,
	SET_USA_STATE,
	SET_ECOCARD_SETUP_ENABLED,
	SET_IS_EMAIL_RECEIPT_ON,
	SET_SOFTWARE_VERSION,
	SET_DEMO_MODE,
	SET_KIOSK_MODE,
	SET_REMOTE_PRINTER,
	SET_EXPRESS_CASH_ENABLED,
	SET_KIOSK_CASH_ENABLED,
	SET_NETWORK_SPEED,
	SET_MACHINE_DATA,
	SET_ASSOCIATED_REDEMPTION_CENTER,
	SET_GUEST_LOGIN,
	SET_KIOSK_DONATIONS_ENABLED,
} from '../actions/types';

const initialState = {
	isAuthenticated: false,
	networkSpeed: '',
	prevLocation: '', // tracks users location in the flow right before they click on the admin page
	materialType: ['plastic'],
	express: false,
	kiosk: true,
	guestLogin: false,
	remotePrinter: false,
	state: '',
	owner: '',
	cert_id: '',
	url: '',
	location: '',
	name: '',
	tenantShortcode: '',
	forceEcoCardSetup: false,
	ecoCardSetupEnabled: false,
	expressCashEnabled: false,
	kioskCashEnabled: false,
	kioskDonationsEnabled: false,
	demoMode: false,
	isEmailReceiptOn: true,
	dailyStats: {},
	versioning: {
		interface: {
			git: '',
			v: '',
		},
		client: {
			git: process.env.REACT_APP_GITHASH || 'n/a',
			v: process.env.REACT_APP_VERSION,
		},
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_DAILY_STATS:
			return {
				...state,
				dailyStats: { ...action.payload },
			};
		case SET_ADMIN_AUTH:
			return {
				...state,
				isAuthenticated: action.payload,
			};
		case SET_USER_FLOW_LOCATION:
			return {
				...state,
				prevLocation: action.payload,
			};
		case SET_MATERIAL_TYPE:
			return {
				...state,
				materialType: action.payload,
			};
		case SET_MACHINE_DATA:
			return {
				...state,
				state: action.payload.state, // CA, NY, etc.
				owner: action.payload.owner,
				cert_id: action.payload.certId,
				location: action.payload.location,
				url: action.payload.url,
				name: action.payload.name,
				tenantShortcode: action.payload.tenantShortcode,
			};
		case SET_ASSOCIATED_REDEMPTION_CENTER: {
			return {
				...state,
				tenantShortcode: action.payload,
			};
		}
		case SET_EXPRESS_MODE:
			return {
				...state,
				express: action.payload,
			};
		case SET_KIOSK_MODE:
			return {
				...state,
				kiosk: action.payload,
			};
		case SET_VIEW_ECOCARD_SETUP:
			return {
				...state,
				forceEcoCardSetup: action.payload,
			};
		case SET_USA_STATE:
			return {
				...state,
				state: action.payload,
			};
		case SET_ECOCARD_SETUP_ENABLED:
			return {
				...state,
				ecoCardSetupEnabled: action.payload,
			};
		case SET_DEMO_MODE:
			return {
				...state,
				demoMode: action.payload,
			};
		case SET_IS_EMAIL_RECEIPT_ON:
			return {
				...state,
				isEmailReceiptOn: action.payload,
			};
		case SET_SOFTWARE_VERSION:
			return {
				...state,
				versioning: {
					...state.versioning,
					interface: {
						git: action.payload.git,
						v: action.payload.v,
					},
				},
			};
		case SET_REMOTE_PRINTER:
			return {
				...state,
				remotePrinter: action.payload,
			};
		case SET_EXPRESS_CASH_ENABLED:
			return {
				...state,
				expressCashEnabled: action.payload,
			};
		case SET_KIOSK_CASH_ENABLED:
			return {
				...state,
				kioskCashEnabled: action.payload,
			};
		case SET_KIOSK_DONATIONS_ENABLED:
			return {
				...state,
				kioskDonationsEnabled: action.payload,
			};
		case SET_NETWORK_SPEED:
			return {
				...state,
				networkSpeed: action.payload,
			};
		case SET_GUEST_LOGIN:
			return {
				...state,
				guestLogin: action.payload,
			};
		case RESET_ADMIN:
			return {
				...state,
				isAuthenticated: false,
				prevLocation: '',
				dailyStats: {},
			};
		default:
			return state;
	}
};
