// lib
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

// actions
import { confirmReceipt, generalErrorModal } from 'redux/actions/modal';
import { getCustomerBalance } from 'redux/actions/user';
import { emailReceipt, finalize } from 'redux/actions/process';
import { SET_COMPLIANCE } from 'redux/actions/types';
import { getMetrics } from 'redux/actions/metrics';

// components
import OverOneHundredUserInfoCA from 'components/CACompliance/OverOneHundredUserInfoCA';
import CheckoutCompleteButton from 'pages/Checkout/CheckoutCompleteButton';
import CustomNotification from 'components/UIKit/CustomNotification';
import CheckoutFooter from 'pages/Checkout/CheckoutFooter';
import CheckoutMain from 'pages/Checkout/CheckoutMain';
import Background from 'components/Background/Background';
import CustomBox from 'components/UIKit/CustomBox';
import Overlay from 'components/UIKit/Overlay';

// utils/lib
import { getTotalContainers, getTotalWeight } from 'utils/totalContainers';
import { screenMaterialEntries } from 'utils/stateCompliance';
import { formatIntlUSD } from 'utils/formatCurrency.js';

const useStyles = makeStyles((theme) => ({
	confirmContainer: {
		marginTop: '40px',
		marginBottom: '30px',
		textAlign: 'center',
	},
	main: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		margin: '8px',
		fontSize: '26px',
		color: theme.palette.secondary.dark,
	},
}));

export default function Checkout() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const containerType = useSelector((state) => state.process.containerType);
	const complianceOverlay = useSelector((state) => state.auth.compliance.open);
	const walletBalance = useSelector((state) => state.auth.wallet);
	const language = useSelector((state) => state.auth.language);
	const metrics = useSelector((state) => state.metrics);
	const data = useSelector((state) => state.process.data);
	const [totalContainers, setTotalContainers] = useState(0);
	const modal = useSelector((state) => state.modal);
	const paymentType = useSelector((state) => state.auth.paymentType);
	const [finalizedBalance, setFinalizedBalance] = useState(false);
	const [open, setOpen] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const prevWalletBalance = walletBalance?.new_customer_balance;
	const newWalletBalance = prevWalletBalance
		? prevWalletBalance / 10000 + data?.redemption_amount_cents
		: data?.redemption_amount_cents;

	const options = {
		mainValue: {
			ecowallet: formatIntlUSD(newWalletBalance, language, 'cents'),
			cash: formatIntlUSD(data?.redemption_amount_cents, language, 'cents'),
			donation: formatIntlUSD(data?.redemption_amount_cents, language, 'cents'), // TODO: needs to accurately reflect changes when counts are adjusted
			card: formatIntlUSD(data?.redemption_amount_cents, language, 'cents'),
		},
		containersRecycled: totalContainers,
		ecowallet: [
			{
				title: t('checkout.todaysDeposit'),
				value: formatIntlUSD(data?.redemption_amount_cents, language, 'cents'),
			},
			{
				title: `CO2 ${t('checkout.emissionsAvoided')}`,
				value: `${(metrics?.totals.co2_g / 1000).toFixed(2)} Kg`,
			},
		],
		cash: t('checkout.receiptWillBePrinted'),
		donation: t('checkout.thanksForDonation'),
		card: t('checkout.balanceAddedToCard'),
		totalWeight: getTotalWeight(),
	};

	useEffect(() => {
		const total = getTotalContainers();
		setTimeout(() => {
			// delay to prevent any lag while backend still processes stats
			dispatch(getCustomerBalance())
				.then(() => {
					setTotalContainers(total);
					setFinalizedBalance(true);
				})
				.catch((err) => {
					setFinalizedBalance(true);
					// TODO: proper error handling for the UI
				});
			dispatch(getMetrics());
			containerType === 'combination' && getOperator();
		}, 5500);
	}, []);

	useEffect(() => {
		if (modal.id) {
			setOpen(true);
		}
	}, [modal]);

	// closes custom notification modal
	const closeMessage = () => {
		setOpen(false);
	};

	const completeTransaction = () => {
		const cashPayment = paymentType === 'cash';
		const status = screenMaterialEntries();

		// send out receipts if needed and complete the kiosk flow
		const complete = () => {
			if (cashPayment) {
				dispatch(emailReceipt());
				setIsButtonDisabled(false);
				history.push('/print-receipt');
			} else if (!cashPayment) {
				dispatch(
					confirmReceipt(
						t('messages.modal.needReceipt'),
						t('messages.modal.confirmPrintReceipt'),
					),
				);
				setIsButtonDisabled(false);
			}
		};

		// run final checks on the transaction eg. overweight, overvalued, etc. and finalize if ok
		if (status.pass) {
			setIsButtonDisabled(true);
			dispatch(finalize()).then(() => {
				complete();
			});
		} else if (status.overWeight) {
			const overweightMaterials =
				status.overWeightItems.length > 0
					? status.overWeightItems.join(', ')
					: status.overWeightItems;

			dispatch(
				generalErrorModal(
					t('messages.modal.overweightPleaseGoBack'),
					overweightMaterials,
					t('messages.modal.overweight'),
				),
			);
		} else if (status.overValue && !status.overWeight) {
			dispatch({
				type: SET_COMPLIANCE,
				payload: true,
			});
		}
	};

	const getTitle = () => {
		let title = '';

		if (paymentType === 'cash') {
			title = t('checkout.cash');
		} else if (paymentType === 'wallet') {
			title = 'EcoWallet';
		} else if (paymentType === 'donate') {
			title = t('checkout.donation');
		} else if (paymentType === 'card') {
			title = 'EcoCard';
		}

		return title;
	};

	return (
		<>
			<Overlay open={complianceOverlay} title='User Info Required' zIndex={525}>
				<OverOneHundredUserInfoCA cancel />
			</Overlay>
			<Background>
				<Grid md={12}>
					<CustomNotification
						open={open}
						id={modal.id}
						heading={modal.heading}
						closeMessage={closeMessage}
						message={modal.message}
					/>
					<CustomBox position='relative' center={true} title={getTitle()}>
						<Box>
							<Box className={classes.main}>
								<CheckoutMain
									finalizedBalance={finalizedBalance}
									options={options}
									paymentType={paymentType}
								/>
							</Box>
							<Box className={classes.confirmContainer}>
								<CheckoutCompleteButton
									finalizedBalance={finalizedBalance}
									disabled={isButtonDisabled}
									completeTransaction={completeTransaction}
								/>
							</Box>
						</Box>
						<CheckoutFooter finalizedBalance={finalizedBalance} />
					</CustomBox>
				</Grid>
			</Background>
		</>
	);
}
