import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import CustomNotification from 'components/UIKit/CustomNotification';
import { generalErrorModal } from 'redux/actions/modal';

// displays notifcation when app goes offline
export default function NoConnection() {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const [online, setOnline] = useState(true);
	const [open, setOpen] = useState(false);
	const modal = useSelector((state) => state.modal);

	useEffect(() => {
		setOnline(navigator.onLine);
	}, []);

	useEffect(() => {
		if (!modal.heading) {
			setOpen(false);
		}
	}, [modal]);

	window.addEventListener('online', () => {
		setOnline(true);
	});

	window.addEventListener('offline', () => {
		setOnline(false);
		dispatch(generalErrorModal('No internet connection. Please alert an operator.'));
	});

	return (
		<CustomNotification
			open={open && !online}
			id={modal.id}
			heading={modal.heading}
			message={modal.message}
		/>
	);
}
