import { store } from 'redux/storage';
import { materialsList } from 'lib/materials';

// Calculations for determining total containers by count or weight
const getEcoCountScrap = () => {
	const unadjustedVal = store.getState().process.data.count_non_redemption;
	const adjustedVal = store.getState().adjustments.ecoCountScrapTotal;

	return adjustedVal ? adjustedVal : unadjustedVal;
};

// -- getTotalContainers notes -- //
// metal subcategories need to be added up together because a catch-all category (eg. metal) cannot be created due to CalRecycle reporting
// oversized containers are included in count_plastic
// count_aluminum = count_alu_lt_24oz + count_alu_geq_24oz
// count_plastic = count_plastic_lt_24oz + count_plastic_geq_24oz
// count_glass = count_glass_lt_24oz + count_glass_geq_24oz
export const getTotalContainers = () => {
	const data = store.getState().process.data;

	const total =
		data.count_aluminum +
		data['count_bi-metal'] +
		data.count_glass +
		data.count_plastic +
		data.count_unknown +
		getEcoCountScrap() +
		data['count_wds-bbp'];

	return total || 0;
};

export const getRedeemableTotalContainers = () => {
	const data = store.getState().process.data;
	const total = data.count_aluminum + data.count_glass + data.count_plastic + data['count_wds-bbp'];

	return total || 'N/A';
};

export const getTotalAdjustments = () => {
	const data = store.getState().adjustments.materials;

	const total =
		data.ALU.lg +
		data.ALU.sm +
		data.PETE.lg +
		data.PETE.sm +
		data.GLS.sm +
		data.GLS.lg +
		data.BMT.sm +
		data.BMT.lg +
		data.HDPE.sm +
		data.HDPE.lg +
		data.PVC.sm +
		data.PVC.lg +
		data.LDPE.sm +
		data.LDPE.lg +
		data.PP.sm +
		data.PP.lg +
		data.PS.sm +
		data.PS.lg +
		data.OTHER.sm +
		data.OTHER.lg +
		data.BIB.lg +
		data.MLP.lg +
		data.PBC.lg;

	return total || 0;
};

// EcoCount scrap is scrap that the machine picks up on and classifies as scrap.
// Not a manual scrap entry.
export const getTotalEcoCountScrap = () => {
	const data = store.getState().adjustments.ecoCountScrap;
	let count = 0;

	if (data) {
		Object.keys(data).map((material) => {
			data[material].forEach((entry) => {
				count = count + entry.count;
			});
		});
	}

	return count;
};

// adds the manual adjustments and the Eco Count containers together
// used in the manuallyChangeCounts payload
export const addEcoCountAndAdjustments = () => {
	const adjustments = store.getState().adjustments.materials;
	const ecoCount = store.getState().process.data;

	const totalCounts = {
		ALU: {
			sm: ecoCount.count_aluminum_lt_24oz + adjustments.ALU.sm,
			lg: ecoCount.count_aluminum_geq_24oz + adjustments.ALU.lg,
		},
		BMT: {
			sm: ecoCount['count_bi-metal_lt_24oz'] + adjustments.BMT.sm,
			lg: ecoCount['count_bi-metal_geq_24oz'] + adjustments.BMT.lg,
		},
		GLS: {
			sm: ecoCount.count_glass_lt_24oz + adjustments.GLS.sm,
			lg: ecoCount.count_glass_geq_24oz + adjustments.GLS.lg,
		},
		PETE: {
			sm: ecoCount.count_pete_lt_24oz + adjustments.PETE.sm,
			lg: ecoCount.count_pete_geq_24oz + adjustments.PETE.lg,
		},
		HDPE: {
			sm: ecoCount.count_hdpe_lt_24oz + adjustments.HDPE.sm,
			lg: ecoCount.count_hdpe_geq_24oz + adjustments.HDPE.lg,
		},
		PVC: {
			sm: ecoCount.count_pvc_lt_24oz + adjustments.PVC.sm,
			lg: ecoCount.count_pvc_geq_24oz + adjustments.PVC.lg,
		},
		LDPE: {
			sm: ecoCount.count_ldpe_lt_24oz + adjustments.LDPE.sm,
			lg: ecoCount.count_ldpe_geq_24oz + adjustments.LDPE.lg,
		},
		PP: {
			sm: ecoCount.count_pp_lt_24oz + adjustments.PP.sm,
			lg: ecoCount.count_pp_geq_24oz + adjustments.PP.lg,
		},
		PS: {
			sm: ecoCount.count_ps_lt_24oz + adjustments.PS.sm,
			lg: ecoCount.count_ps_geq_24oz + adjustments.PS.lg,
		},
		BIB: {
			sm: 0,
			lg: ecoCount['count_bag-in-box'] + adjustments.BIB.lg,
		},
		MLP: {
			sm: 0,
			lg: ecoCount['count_multilayer-pouch'] + adjustments.MLP.lg,
		},
		PBC: {
			sm: 0,
			lg: ecoCount['count_paperboard-carton'] + adjustments.PBC.lg,
		},
		OTHER: {
			sm: ecoCount.count_other_lt_24oz + adjustments.OTHER.sm,
			lg: ecoCount.count_other_geq_24oz + adjustments.OTHER.lg,
		},
	};

	return totalCounts;
};

// calculates the weight entry of a material type minus the tare weight
// eg. 2 lbs of aluminum and the bucket that holds the aluminum weighs 0.5 so net weight is 1.5 lbs
export const calculateNetWeights = (grossWeight, tareWeight) => {
	return new Promise((resolve) => {
		const netWeights = {};

		Object.keys(grossWeight).map((material) => {
			netWeights[material] = Number(grossWeight[material]) - Number(tareWeight[material] ?? 0);
		});

		resolve(netWeights);
	});
};

// calculates the total of all material type weights
export const getTotalWeight = () => {
	const data = store.getState().process.data;

	const total =
		data.weight_aluminum_lbs +
		data['weight_bag-in-box_lbs'] +
		data['weight_bi-metal_lbs'] +
		data.weight_glass_lbs +
		data.weight_hdpe_lbs +
		data.weight_ldpe_lbs +
		data['weight_multilayer-pouch_lbs'] +
		data.weight_other_lbs +
		data['weight_paperboard-carton_lbs'] +
		data.weight_pete_lbs +
		data.weight_pp_lbs +
		data.weight_ps_lbs;

	return total || 'N/A';
};

// formats materials to list ones that are used eg. '[PETE, ALU, LDPE]'
export const getCurrentMaterials = (counts, weights, scrap) => {
	let currentMaterials = [];

	// map over counts and detect which ones have been touched
	counts &&
		Object.entries(counts).map((material) => {
			if ((material[1].sm || material[1].lg) > 0) {
				currentMaterials.push(material[0]);
			}
		});

	// map over weights and detect which ones have been touched
	weights &&
		Object.entries(weights).map((material) => {
			if (material[1] > 0) {
				currentMaterials.push(material[0]);
			}
		});

	// map over the scrap entries and detect which ones have been touched
	scrap &&
		Object.entries(scrap).map((material) => {
			if (material[1].length > 0) {
				currentMaterials.push(material[0]);
			}
		});

	return currentMaterials;
};

// filter the overweight materials to only include the ones that are used in the current transaction
export const getCurrentOverweightMaterials = () => {
	const weightLimits = store.getState().process.dailyWeightLimits;
	const currentMaterials = store.getState().process.currentMaterials;
	let currentOverweightMaterials = [];

	// cover material names like 'ALU' or 'Aluminum' and then filter out overweight materials not included in current transaction
	materialsList.map((name) => {
		const current =
			currentMaterials.includes(name.full) ||
			currentMaterials.includes(name.short) ||
			currentMaterials.includes(name.alternate);

		const overweight =
			weightLimits.overweight.includes(name.full) ||
			weightLimits.overweight.includes(name.short) ||
			weightLimits.overweight.includes(name.alternate);

		if (current) {
			overweight && currentOverweightMaterials.push(name.full);
		}
	});

	return currentOverweightMaterials;
};
