import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { logout, login } from 'redux/actions/auth';
import { setPaymentType } from 'redux/actions/user';
import { clearModal, emergencyStopModal, confirmEmergencyStopModal } from 'redux/actions/modal';
import { emailReceipt, resumeTask } from 'redux/actions/process';
import {
	NEXT_STEP,
	SET_USER_FLOW_LOCATION,
	SET_MATERIAL_TO_LOAD,
	SET_COMPLIANCE,
} from 'redux/actions/types';
import CustomButton from 'components/UIKit/CustomButton';
import AutoLogoutCount from 'components/AutoLogoutCount';
import cautionImg from 'assets/images/causion.png';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import { isUserInfoNeededCA } from 'utils/stateCompliance';

const useStyles = makeStyles((theme) => ({
	bgBlur: {
		position: 'sticky',
		top: '0',
		left: '0',
		minWidth: '100vw',
		minHeight: '100vh',
		zIndex: '615',
		backgroundColor: 'rgba(255, 255, 255, 0.80)',
		backdropFilter: 'blur(4px)',
	},
	adminButton: {
		width: '300px',
		height: '130px',
	},
	modal: {
		position: 'fixed',
		width: '690px',
		height: 'max-content',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
		backgroundColor: theme.palette.background.default,
		borderRadius: '18px',
		border: '1px solid #000',
		overflow: 'hidden',
		zIndex: '625',
		[theme.breakpoints.down('lg')]: {
			minHeight: '400px',
		},
	},
	title: {
		borderBottom: '1px solid #000',
		backgroundColor: theme.palette.primary.dark,
		padding: '4px',
		display: 'flex',
		alignItems: 'center',
		'& img': {
			height: '40px',
			margin: '0px 10px',
		},
		'& .MuiTypography-root': {
			color: theme.palette.text.secondary,
			fontWeight: '700',
			letterSpacing: '1px',
		},
		'& .MuiSvgIcon-root': {
			height: '28px',
			width: 'auto',
			margin: '0px 10px',
		},
	},
	titleError: {
		backgroundColor: theme.palette.error.main,
	},
	titleSuccess: {
		backgroundColor: theme.palette.primary.dark,
	},
	titleWarning: {
		backgroundColor: '#FFC845',
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-end',
	},
	message: {
		padding: '16px 100px 28px 24px',
		'& .MuiTypography-root': {
			fontWeight: '200',
			lineHeight: '1.2',
		},
	},
	footer: {
		borderTop: '1px solid #000',
		display: 'flex',
		padding: '22px',
		justifyContent: 'space-around',
		width: '100%',
		alignItems: 'center',
	},
	alternateFooter: {
		marginBottom: '14px',
	},
	primaryButtonGroup: {
		display: 'flex',
		justifyContent: 'space-around',
		marginBottom: '30px',
		alignItems: 'center',
	},
	multiButtonGroup: {
		width: '100%',
	},
	secondaryButtonGroup: {
		display: 'flex',
		justifyContent: 'center',
	},
	info: {
		textAlign: 'center',
		marginBottom: '20px',
		'& .MuiTypography-root': {
			color: theme.palette.error.main,
			fontWeight: '500',
		},
	},
}));

export default function Modal({
	id,
	open,
	heading,
	message,
	closeMessage,
	startMachineExpressMode,
	disabled,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const payment = useSelector((state) => state.auth.paymentData);
	const email = useSelector((state) => state.auth.customer.email);
	const data = useSelector((state) => state.modal);
	const step = useSelector((state) => state.steps.activeStep);
	const eStop = useSelector((state) => state.process.data.estop_state);
	const emailReceiptEnabled = useSelector((state) => state.admin.isEmailReceiptOn);
	const [count, setCount] = useState(0);

	const title = clsx({
		[classes.title]: true,
		[classes.titleError]: data.type === 'error',
		[classes.titleWarning]: data.type === 'warning',
		[classes.titleSuccess]: data.type === ('success' || 'ok'),
	});

	const footer = clsx({
		[classes.footer]: true,
		[classes.alternateFooter]: data.id === 11,
	});

	const beginProcessRedirect = () => {
		closeMessage();
		dispatch(clearModal());

		if (history.location.state?.setupEcoCard) {
			const pathname = '/express';

			history.push({ pathname, state: { ecoCardSetup: true } });

			dispatch(login(email));
			dispatch(
				setPaymentType(
					payment.keep.methods.card.name,
					payment.keep.methods.card.id,
					payment.keep.id,
				),
			);
		} else {
			history.push('/begin-process');
		}
	};

	const logoutUser = () => {
		if (isUserInfoNeededCA()) {
			closeMessage();
			dispatch(clearModal());
			dispatch({
				type: SET_COMPLIANCE,
				payload: true,
			});
		} else {
			closeMessage();
			dispatch(clearModal());
			dispatch(logout()).then(() => {
				history.push('/sign-in');
			});
		}
	};

	const printReceipt = () => {
		closeMessage(); //TODO: figure out why this doesn't work. UPDATE: this might work now... remove printReceiptPage to test
		dispatch(clearModal());
		emailReceiptEnabled && dispatch(emailReceipt());
		dispatch({ type: NEXT_STEP, payload: step });
		history.push('/print-receipt');
	};

	const completeProcess = () => {
		closeMessage();
		dispatch(clearModal());
		emailReceiptEnabled && dispatch(emailReceipt());
		dispatch({ type: NEXT_STEP, payload: step + 1 });
		history.push('/thank-you');
	};

	const selectCharityRedirect = () => {
		const donatePage = window.location.toString().includes('/donate');
		closeMessage();
		dispatch(clearModal());
		if (donatePage) history.push('/begin-process');
		if (!donatePage) history.push('/donate');
	};

	//** Currently not working */
	const cancelEmergencyStopClicked = () => {
		// closeMessage();
		// dispatch(clearModal());
		dispatch(confirmEmergencyStopModal('Are you sure you want to start the machine again?'));
	};

	const emergencyBack = () => {
		closeMessage();
		dispatch(clearModal());
		dispatch(
			emergencyStopModal("Emergency stop activated. Press 'Start' to start the machine again."),
		);
	};

	const close = () => {
		closeMessage();
		dispatch(clearModal());
	};

	// if the logo area is clicked a few times it will redirect to admin page
	const handleLogoClick = () => {
		setCount((prevState) => prevState + 1);
		if (count === 4) {
			dispatch({
				type: SET_USER_FLOW_LOCATION,
				payload: window.location.pathname,
			});
			history.push('/admin/adjust-counts');
			setCount(0);
		}
	};

	const setMaterialToLoad = (material) => {
		dispatch({
			type: SET_MATERIAL_TO_LOAD,
			payload: material,
		});
		close();
		startMachineExpressMode();
	};

	const handleAluminumSelected = () => {
		setMaterialToLoad('aluminum');
	};

	const handlePlasticSelected = () => {
		setMaterialToLoad('plastic');
	};

	const setOtherMaterialType = () => {
		dispatch({
			type: SET_MATERIAL_TO_LOAD,
			payload: 'other',
		});
		close();
	};

	const handleResume = () => {
		closeMessage();
		dispatch(clearModal());
		dispatch(resumeTask());
	};

	return (
		<>
			{open && data.id !== 0 && (
				<>
					<Box className={classes.modal}>
						<Box className={title}>
							{data.type === 'error' && <img src={cautionImg} alt='caution sign' />}
							{data.type === ('ok' || '') && <CheckCircleIcon />}
							<Typography variant='h4'>{heading}:</Typography>
						</Box>
						<Box className={classes.main}>
							<Box className={classes.message}>
								<Typography variant='h4'>{message}</Typography>
							</Box>
							{data.info && data.type === 'error' && (
								<Box className={classes.info}>
									<Typography variant='h6'>{data.info}</Typography>
								</Box>
							)}
							<Box className={footer}>
								{(() => {
									switch (data.id) {
										case 1: // standard login error
											return (
												<CustomButton onClick={close}>
													{t('messages.modal.ok')}
												</CustomButton>
											);
										case 2: // logout confirmation
											return (
												<>
													<CustomButton variant='alternate' onClick={close}>
														{t('messages.modal.cancel')}
													</CustomButton>
													<CustomButton
														variant='alternate'
														onClick={logoutUser}
													>
														{t('messages.modal.logout')}
													</CustomButton>
												</>
											);
										case 3: // EcoWallet payment confirmation
											return (
												<>
													<CustomButton variant='alternate' onClick={close}>
														{t('messages.modal.cancel')}
													</CustomButton>
													<CustomButton onClick={beginProcessRedirect}>
														{t('messages.modal.ok')}
													</CustomButton>
												</>
											);
										case 4: // Cash payment confirmation
											return (
												<>
													<CustomButton variant='alternate' onClick={close}>
														{t('messages.modal.cancel')}
													</CustomButton>
													<CustomButton onClick={beginProcessRedirect}>
														{t('messages.modal.ok')}
													</CustomButton>
												</>
											);
										case 5: // charity selection confirmation
											return (
												<>
													<CustomButton variant='alternate' onClick={close}>
														{t('messages.modal.cancel')}
													</CustomButton>
													<CustomButton onClick={selectCharityRedirect}>
														{t('messages.modal.ok')}
													</CustomButton>
												</>
											);
										case 6: // print receipt confirmation (no receipt = emailed only, print = emailed and printed)
											return (
												<>
													<CustomButton
														size='expanded'
														onClick={() => completeProcess()}
													>
														{t('messages.modal.noReceipt')}
													</CustomButton>
													<CustomButton size='expanded' onClick={printReceipt}>
														{t('messages.modal.yesPrintReceipt')}
													</CustomButton>
												</>
											);
										case 7: // logout confirmation (if inactive for a few minutes)
											return (
												<Box className={classes.multiButtonGroup}>
													<Box className={classes.primaryButtonGroup}>
														<CustomButton variant='alternate' onClick={close}>
															{t('messages.modal.cancel')}
														</CustomButton>
														<CustomButton
															variant='alternate'
															onClick={logoutUser}
														>
															{t('messages.modal.logout')}
														</CustomButton>
													</Box>
													<Box className={classes.secondaryButtonGroup}>
														<AutoLogoutCount logout={logoutUser} />
													</Box>
												</Box>
											);
										case 8: // emergency stop initial modal if machine stopped
											return (
												<CustomButton onClick={cancelEmergencyStopClicked}>
													Start
												</CustomButton>
											);
										case 9: // emergency stop confirmation resume machine
											return (
												<>
													<CustomButton>Yes, Start Machine</CustomButton>
													<CustomButton onClick={emergencyBack}>
														Back
													</CustomButton>
												</>
											);
										case 10: // emergency stop failed to shut down machine, no task id
											return (
												<>
													<Box>Emergency Stop Failed</Box>
													<CustomButton onClick={close}>OK</CustomButton>
												</>
											);
										// TODO: Refactor similar modals into the same one
										case 12: // error msg if the machine belt failed to start running
											return (
												<CustomButton onClick={close}>
													{t('messages.modal.retry')}
												</CustomButton>
											);
										case 13: // when no items are detected when trying to start the machine
											return (
												<CustomButton onClick={close}>
													{t('messages.modal.ok')}
												</CustomButton>
											);
										case 14: // error msg for print receipt endpoint
											return (
												<CustomButton onClick={close}>
													{t('messages.modal.ok')}
												</CustomButton>
											);
										case 15: // prompt after processing items to get an operator for oversized items
											return (
												<CustomButton onClick={close}>
													{t('messages.modal.ok')}
												</CustomButton>
											);
										case 17: // general error message TODO: refactor some of the other error messages to maybe use this general error message
											return (
												<CustomButton onClick={close}>
													{t('messages.modal.ok')}
												</CustomButton>
											);
										case 18: // success message when the ecocard gets setup
											return (
												<CustomButton onClick={beginProcessRedirect}>
													{t('messages.modal.ok')}
												</CustomButton>
											);
										case 19: // selecting 'aluminum' or 'plastic' to run in express mode
											return (
												<Box className={classes.multiButtonGroup}>
													<Box className={classes.primaryButtonGroup}>
														<CustomButton
															disabled={disabled}
															onClick={handlePlasticSelected}
														>
															{t('admin.plastic')}
														</CustomButton>
														<CustomButton
															disabled={disabled}
															onClick={handleAluminumSelected}
														>
															{t('admin.aluminum')}
														</CustomButton>
													</Box>
													<Box className={classes.secondaryButtonGroup}>
														<CustomButton
															variant='alternate'
															size='small'
															onClick={close}
														>
															{t('common.buttons.cancel')}
														</CustomButton>
													</Box>
												</Box>
											);
										case 20: // when a user selects oversize, glass, or other material type to process in Kiosk flow
											return (
												<>
													<CustomButton onClick={setOtherMaterialType}>
														{t('common.buttons.no')}
													</CustomButton>
													<CustomButton onClick={close}>
														{t('common.buttons.yes')}
													</CustomButton>
												</>
											);
										case 21: // when the emergency stop is pressed
											return (
												<>
													<CustomButton disabled={eStop} onClick={handleResume}>
														{t('common.buttons.resume')}
													</CustomButton>
												</>
											);
										default:
											return null;
									}
								})()}
							</Box>
						</Box>
					</Box>
					<Box className={classes.bgBlur}>
						<Box className={classes.adminButton} onClick={handleLogoClick}></Box>
					</Box>
				</>
			)}
		</>
	);
}
