import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	container: {},
	message: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
		'& .MuiTypography-root': {
			marginLeft: '6px',
		},
	},
}));

export default function AdminMessages() {
	const classes = useStyles();
	const { t } = useTranslation();
	const newVersionAvailable = useSelector((state) => state.userInterface.newVersionAvailable);
	const [messageList, setMessageList] = useState([]);

	const messages = {
		newVersion: t('admin.pleaseLoginAndUpdate'),
	};

	useEffect(() => {
		getMessages();
	}, []);

	const removeMessage = (message) => {
		let newMessageList = [];

		if (messageList.includes(message)) {
			newMessageList = messageList.filter((item) => item !== message);
		} else {
			newMessageList = messageList;
		}

		setMessageList(newMessageList);
	};

	const getMessages = () => {
		newVersionAvailable && setMessageList([...messageList, messages.newVersion]);
		!newVersionAvailable && removeMessage(messages.newVersion);
	};

	return (
		<Box>
			{messageList.map((message) => (
				<Box className={classes.message}>
					<NotificationsNoneIcon />
					<Typography>{message}</Typography>
				</Box>
			))}
		</Box>
	);
}
