// lib
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// mui
import Typography from '@material-ui/core/Typography';
import CustomBox from 'components/UIKit/CustomBox';
import Box from '@material-ui/core/Box';

// assets/components
import ProcessingItemCard from 'components/UIKit/ProcessingItemCard/ProcessingItemCard';
import CustomButton from 'components/UIKit/CustomButton';
import cautionImg from 'assets/images/causion.png';
import binIcon from 'assets/images/recycletek-full-bin-icon.png';

const useStyles = makeStyles((theme) => ({
	main: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginBottom: '100px',
	},
	container: {
		position: 'relative',
		border: '1px solid rgba(0,0,0,0.3)',
		borderRadius: '10px',
		color: '#004F71',
		maxWidth: '494px',
		minWidth: '494px',
		minHeight: '260px',
		height: 'max-content',
		marginLeft: '-24px',
		backgroundColor: '#FFF',
		boxShadow: '2px 5px 3px rgba(0, 0, 0, 0.4)',
		overflow: 'hidden',
	},
	heading: {
		borderBottom: '1px solid rgba(0,0,0,0.3)',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'& img': {
			marginLeft: '10px',
			height: '26px',
			width: 'auto',
			marginBottom: '-2px',
			animation: '$pulse 3000ms infinite',
		},
		'& .MuiTypography-root': {
			margin: '8px 14px 4px 10px',
			animation: '$pulse 3000ms infinite',
		},
	},
	text: {
		margin: '14px 18px',
	},
	section: {
		border: '1px solid red',
		width: '100%',
	},
	items: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '30px',
	},
	count: {
		display: 'flex',
		flexDirection: 'row',
	},
	bin: {
		position: 'absolute',
		right: '160px',
		bottom: '-6px',
		'& img': {
			height: '180px',
			width: 'auto',
		},
	},
	resumeButton: {
		marginLeft: '150px',
	},
	['@keyframes pulse']: {
		'0%': { transform: 'scale(0.85)' },
		'50%': { transform: 'scale(1.1)' },
		'100%': { transform: 'scale(0.85)' },
	},
}));

export default function MachinePaused() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const plasticCount = useSelector((state) => state.process.data.count_plastic);
	const aluminumCount = useSelector((state) => state.process.data.count_aluminum);
	const nonDepositCount = useSelector((state) => state.process.data.count_non_redemption);
	const prevLocation = useSelector((state) => state.admin.prevLocation);
	const full = useSelector((state) => state.process.data.bag_full);

	// IMPORTANT: Bag full sensors not functional, feature is WIP until mechanical functionality is aligned
	// this hook calls /get_task_stats which has the bag_full flag, if the bag sensors show it is not full (bag_full: false) user will be redirected to the start machine page where /resume is called
	// NOTE: useInterval is deleted, use setInterval and clearInterval instead, see startProcess.js for an example
	// useInterval(async () => {
	// 	dispatch(getTaskStats());
	// 	if (!full) {
	// 		history.push('/eco-vision');
	// 	}
	// }, delay);

	const manuallyResumeMachine = () => {
		history.push(prevLocation === '/express' ? '/express' : '/eco-vision');
	};

	// TODO: Fix processing item card, maybe just show the counts
	return (
		<CustomBox title='Machine Paused' yellowHeading position='relative'>
			<Box className={classes.main}>
				<Box className={classes.container}>
					<Box className={classes.heading}>
						<img src={cautionImg} alt='caution sign' />
						<Typography>{t('machinePaused.attention')}</Typography>
					</Box>
					<Box className={classes.text}>
						<Typography>
							<Trans>{t('machinePaused.collectionBagFullText')}</Trans>
						</Typography>
					</Box>
				</Box>
				<Box className={classes.items}>
					<Typography variant='h6'>{t('machinePaused.itemsRecycled')}</Typography>
					<Box className={classes.count}>
						<ProcessingItemCard
							count={plasticCount}
							title={t('startProcess.plasticBottles')}
						/>
						<ProcessingItemCard
							count={aluminumCount}
							title={t('startProcess.aluminumCans')}
						/>
						<ProcessingItemCard
							count={nonDepositCount}
							color='#BE4D00'
							title={t('startProcess.nonDeposit')}
						/>
					</Box>
				</Box>
				<Box className={classes.bin}>
					<img src={binIcon} alt='recycling bin full icon' />
				</Box>
			</Box>
			<Box className={classes.resumeButton}>
				<CustomButton onClick={manuallyResumeMachine}>{t('common.buttons.resume')}</CustomButton>
			</Box>
		</CustomBox>
	);
}
