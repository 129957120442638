export const saveToLocalStorage = (key, state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(key, serializedState);
	} catch (error) {
		console.error('Error saving state to localStorage:', error);
	}
};

export const loadFromLocalStorage = (key) => {
	try {
		const serializedState = localStorage.getItem(key);
		return serializedState ? JSON.parse(serializedState) : undefined;
	} catch (error) {
		console.error('Error loading state from localStorage:', error);
		return undefined;
	}
};
