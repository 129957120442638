import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomNotification from 'components/UIKit/CustomNotification';
import { useTranslation } from 'react-i18next';

export default function SnackbarAndModalController() {
	const { t, i18n } = useTranslation();
	const modal = useSelector((state) => state.modal);
	const [open, setOpen] = useState(false);

	// close modal
	useEffect(() => {
		if (!modal.heading) {
			setOpen(false);
		}
	}, [modal]);

	return (
		<>
			<CustomNotification
				open={open}
				id={modal.id}
				heading={modal.heading}
				message={modal.message}
			/>
		</>
	);
}
