import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

// components
import CustomButton from 'components/UIKit/CustomButton';
import MaterialTypeNumber from 'components/UIKit/MaterialTypeNumber';

// utils and lib
import { addEcoCountAndAdjustments } from 'utils/totalContainers';

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '10px 0px',
		maxHeight: 300,
	},
	table: {
		minWidth: 650,
	},
	material: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function CountEntriesTable({ editCount }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const ecoCount = useSelector((state) => state.process.data);
	const adjustments = useSelector((state) => state.adjustments.materials);
	const [totals, setTotals] = useState({});

	useEffect(() => {
		const sum = addEcoCountAndAdjustments();
		// ecoCount or process.data returns an empty object if ecoCount is not run
		setTotals(Object.keys(ecoCount).length > 0 ? sum : adjustments);
	}, []);

	return (
		<TableContainer className={classes.container} component={Paper}>
			<Table className={classes.table} stickyHeader aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell>{t('overlay.review.material')}</TableCell>
						<TableCell align='right'>{t('overlay.review.count')} &lt; 24oz</TableCell>
						<TableCell align='right'>{t('overlay.review.count')} ≥ 24oz</TableCell>
						<TableCell align='right'>{t('overlay.review.total')}</TableCell>
						<TableCell align='right'>{t('overlay.review.editEntry')}</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{Object.keys(totals).map(
						(material, index) =>
							totals[material].sm + totals[material].lg !== 0 && (
								<TableRow key={index}>
									<TableCell component='th' scope='row'>
										<Box className={classes.material}>
											{material}
											<MaterialTypeNumber type={material} />
										</Box>
									</TableCell>
									<TableCell align='right'>{totals[material].sm}</TableCell>
									<TableCell align='right'>{totals[material].lg}</TableCell>
									<TableCell align='right'>
										{totals[material].lg + totals[material].sm}
									</TableCell>

									<TableCell align='right'>
										<CustomButton
											size='extra-small'
											variant='secondary'
											name={`cy-edit-count-${material}`}
											onClick={() => editCount(material)}
										>
											<EditIcon />
										</CustomButton>
									</TableCell>
								</TableRow>
							),
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
