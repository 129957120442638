// lib
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// components
import OverOneHundredUserInfoCA from 'components/CACompliance/OverOneHundredUserInfoCA';
import ContainerInputTypes from 'components/ContainerInputTypes';
import ExpressInfoSection from 'pages/Express/ExpressInfoSection';
import CustomNotification from 'components/UIKit/CustomNotification';
import ReviewAndFinalize from 'pages/Express/ReviewAndFinalize/ReviewAndFinalize';
import ExpressControls from 'pages/Express/ExpressControls';
import ScrollContainer from 'components/UIKit/ScrollContainer';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import RecycletekLogo from 'components/Background/RecycletekLogo';
import MaterialPanel from 'components/UIKit/MaterialPanel';
import LogoutButton from 'components/Background/LogoutButton';
import CheckPaymentPreference from 'components/CheckPaymentPreference';
import { setLoading } from 'redux/actions/userInterface';
import WeightInputs from 'components/CountsAndWeight/WeightInputs';
import ScrapInputs from 'components/CountsAndWeight/ScrapInputs';
import CountInputs from 'components/CountsAndWeight/CountInputs';
import TabMenu from 'components/UIKit/TabMenu';
import Overlay from 'components/UIKit/Overlay';
import { generalErrorModal } from 'redux/actions/modal';

// redux
import {
	getTaskStats,
	pauseTask,
	taskStarted,
	stopProcess,
	resumeTask,
	finalize,
	manuallyInsertCounts,
	manuallyChangeCounts,
} from 'redux/actions/process';
import { MODAL_SELECT_MATERIAL_TO_RUN, MODAL_LOGOUT, SET_COMPLIANCE } from 'redux/actions/types';

// utils/lib
import {
	getTotalAdjustments,
	addEcoCountAndAdjustments,
	calculateNetWeights,
} from 'utils/totalContainers';
import { materialTabs } from 'lib/tabs';
import { isScrapEntered } from 'utils/scrap';
import { screenMaterialEntries } from 'utils/stateCompliance';

const useStyles = makeStyles((theme) => ({
	main: {
		display: 'flex',
		justifyContent: 'center',
	},
	materials: {
		height: '100vh',
		paddingBottom: '120px',
		[theme.breakpoints.down('lg')]: {
			paddingBottom: '190px',
		},
	},
	materialContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	controlsContainer: {
		position: 'absolute',
		right: '0px',
		top: '155px',
		[theme.breakpoints.down('lg')]: {
			borderTop: `1px solid ${theme.palette.grey.main}`,
			backgroundColor: theme.palette.background.default,
			height: '100px',
			position: 'fixed',
			top: 'unset',
			bottom: '0px',
			marginLeft: 'auto',
			marginRight: 'auto',
			width: '100%',
		},
	},
	infoContainer: {
		position: 'absolute',
		left: '20px',
		top: '158px',
		'@media (max-width: 1265px)': {
			position: 'absolute',
			left: 'unset',
			top: '20px',
			right: '40px',
		},
	},
	logout: {
		position: 'fixed',
		bottom: '10px',
		left: '10px',
	},
}));

// Eg. Count, Weight, or Scrap for the selected type of entry
const initSelectedInputType = {
	['PETE']: 'Count',
	['ALU']: 'Count',
	['GLS']: 'Count',
	['HDPE']: 'Count',
	['PVC']: 'Count',
	['LDPE']: 'Count',
	['BMT']: 'Count',
	['PP']: 'Count',
	['PS']: 'Count',
	['OTHER']: 'Count',
	['BIB']: 'Count',
	['MLP']: 'Count',
	['PBC']: 'Count',
};

export default function ExpressV2() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const readyToStop = useSelector((state) => state.process.ready_to_stop);
	const complianceOverlay = useSelector((state) => state.auth.compliance.open);
	const taskId = useSelector((state) => state.process.task_id?.task_id);
	const prevTaskId = useSelector((state) => state.process.prevTaskId);
	const modal = useSelector((state) => state.modal);
	const data = useSelector((state) => state.process.data);
	const loading = useSelector((state) => state.userInterface.loading);
	const full = useSelector((state) => state.process.data.bag_full);
	const [selectedInputType, setSelectedInputType] = useState(initSelectedInputType);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState({ text: '', status: '' });
	const [isBeltRunning, setIsBeltRunning] = useState(false);
	const [selectedTab, setSelectedTab] = useState('Plastic');
	const [tareWeights, setTareWeights] = useState({});
	const [taskEnded, setTaskEnded] = useState(false);
	const [weights, setWeights] = useState({});
	const [reviewOverlay, setReviewOverlay] = useState(false);
	const [password, setPassword] = useState('');
	const [open, setOpen] = useState(false);

	const materials = [
		{
			type: 'PETE',
			altType: 'pete',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true, // if set to false, "N/A" will be displayed in the EcoCount section and will not pickup on materials that the EcoCount detects
				ecoCount: data.count_pete_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_pete_geq_24oz,
			},
		},
		{
			type: 'ALU',
			altType: 'aluminum',
			displayName: 'Aluminum',
			selected: selectedTab === 'Metal',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_aluminum_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_aluminum_geq_24oz,
			},
		},
		{
			type: 'GLS',
			altType: 'glass',
			displayName: 'Glass',
			selected: selectedTab === 'Glass',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_glass_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_glass_geq_24oz,
			},
		},
		{
			type: 'HDPE',
			altType: 'hdpe',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_hdpe_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_hdpe_geq_24oz,
			},
		},
		{
			type: 'PVC',
			altType: 'pvc',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_pvc_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_pvc_geq_24oz,
			},
		},
		{
			type: 'LDPE',
			altType: 'ldpe',
			scrap: true,
			selected: selectedTab === 'Plastic',
			sm: {
				available: true,
				ecoCount: data.count_ldpe_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_ldpe_geq_24oz,
			},
		},
		{
			type: 'BMT',
			altType: 'bi-metal',
			displayName: 'Bi-Metal',
			scrap: true,
			selected: selectedTab === 'Metal',
			sm: {
				available: true,
				ecoCount: data['count_bi-metal_lt_24oz'],
			},
			lg: {
				available: true,
				ecoCount: data['count_bi-metal_geq_24oz'],
			},
		},
		{
			type: 'PP',
			altType: 'pp',
			scrap: true,
			selected: selectedTab === 'Plastic',
			sm: {
				available: true,
				ecoCount: data.count_pp_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_pp_geq_24oz,
			},
		},
		{
			type: 'PS',
			altType: 'ps',
			scrap: true,
			selected: selectedTab === 'Plastic',
			sm: {
				available: true,
				ecoCount: data.count_ps_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_ps_geq_24oz,
			},
		},
		{
			type: 'OTHER',
			altType: 'other',
			displayName: 'Other',
			selected: selectedTab === 'Plastic',
			scrap: true,
			sm: {
				available: true,
				ecoCount: data.count_other_lt_24oz,
			},
			lg: {
				available: true,
				ecoCount: data.count_other_geq_24oz,
			},
		},
		{
			type: 'BIB',
			displayName: 'Bag-in-Box',
			selected: selectedTab === 'WDS-BBP',
			oneSize: true,
			sm: {
				available: false,
				ecoCount: 0,
			},
			lg: {
				available: true,
				ecoCount: data['count_bag-in-box'],
			},
		},
		{
			type: 'MLP',
			displayName: 'Multilayer-Pouch',
			selected: selectedTab === 'WDS-BBP',
			oneSize: true,
			sm: {
				available: true,
				ecoCount: 0,
			},
			lg: {
				available: true,
				ecoCount: data['count_multilayer-pouch'],
			},
		},
		{
			type: 'PBC',
			displayName: 'Paperboard-Carton',
			selected: selectedTab === 'WDS-BBP',
			oneSize: true,
			sm: {
				available: true,
				ecoCount: 0,
			},
			lg: {
				available: true,
				ecoCount: data['count_paperboard-carton'],
			},
		},
	];

	useEffect(() => {
		if (modal.id) {
			setOpen(true);
		}
	}, [modal]);

	useEffect(() => {
		const interval = setInterval(() => {
			taskId && isBeltRunning && dispatch(getTaskStats());
		}, 2500);

		return () => clearInterval(interval);
	}, [isBeltRunning]);

	useEffect(() => {
		if (full) {
			dispatch(pauseTask());
			history.push('/machine-paused');
		}
	}, [full]);

	const setTab = (tab) => {
		setSelectedTab(tab);
	};

	// closes the custom notification modal
	const closeMessage = () => {
		setOpen(false);
	};

	const start = () => {
		setIsButtonDisabled(true);
		dispatch(taskId ? resumeTask() : taskStarted())
			.then(() => {
				setIsBeltRunning(true);
				setSnackbarMessage({
					text: t('messages.success.machineRunning'),
					status: 'success',
				});
				setIsButtonDisabled(false);
			})
			.catch(() => {
				setIsButtonDisabled(false);
			});
	};

	const selectMaterialToRun = () => {
		dispatch({
			type: MODAL_SELECT_MATERIAL_TO_RUN,
			payload: {
				message: t('messages.modal.chooseMaterial'),
				heading: t('messages.modal.pleaseSelect'),
			},
		});
	};

	const stop = () => {
		readyToStop &&
			dispatch(pauseTask()).then(() => {
				setIsBeltRunning(false);
				setSnackbarMessage({
					text: t('messages.success.machineStopped'),
					status: 'success',
				});
			});
	};

	const handleOverWeight = (data, reason) => {
		const overweightMaterials = data.length > 0 ? data.join(', ') : data;

		closeReviewOverlay();

		if (reason === 'overweight') {
			dispatch(
				generalErrorModal(t('messages.modal.overweightItems'), overweightMaterials),
				t('messages.modal.overweight'),
			);
		}
	};

	const handleEndTask = () => {
		dispatch(finalize()).then(() => {
			setTaskEnded(true);
		});
	};

	const handleMaterialsSubmitted = () => {
		dispatch(getTaskStats()).then(() => {
			const status = screenMaterialEntries();

			if (status.pass) {
				handleEndTask();
			} else if (status.overWeight) {
				dispatch(setLoading(false));
				handleOverWeight(status.overWeightItems, 'overweight');
			} else if (status.overValue && !status.overWeight) {
				dispatch(setLoading(false));
				dispatch({
					type: SET_COMPLIANCE,
					payload: true,
				});
			}
		});
	};

	const end = async () => {
		const netWeights = await calculateNetWeights(weights, tareWeights);
		const scrapEntries = isScrapEntered();
		const weightEntries = Object.keys(weights).length > 0;
		const adjustedCounts = getTotalAdjustments() !== '' || weightEntries || scrapEntries;
		const totalCounts = addEcoCountAndAdjustments();
		const id = taskId || prevTaskId;

		if (adjustedCounts && !id) {
			dispatch(manuallyInsertCounts(netWeights)).then(() => {
				handleMaterialsSubmitted();
			});
		} else if (taskId && !adjustedCounts) {
			dispatch(stopProcess()).then(() => {
				handleMaterialsSubmitted();
			});
		} else if (id && adjustedCounts) {
			taskId && dispatch(stopProcess());
			dispatch(manuallyChangeCounts(totalCounts, id, password, netWeights)).then(() => {
				handleMaterialsSubmitted();
			});
		}
	};

	const logout = () => {
		dispatch({
			type: MODAL_LOGOUT,
			payload: {
				message: t('messages.modal.confirmLogout'),
				heading: t('messages.modal.pleaseConfirm'),
			},
		});
	};

	const closeReviewOverlay = () => {
		setReviewOverlay(false);
		setTaskEnded(false);
		loading && dispatch(setLoading(false));
	};

	const closeSnackbar = () => {
		setSnackbarMessage({ text: '', status: '' });
	};

	const handleGrossWeight = (weight, material) => {
		setWeights((prevState) => ({
			...prevState,
			[material]: weight,
		}));
	};

	const handleTareWeight = (weight, material) => {
		setTareWeights((prevState) => ({
			...prevState,
			[material]: weight,
		}));
	};

	const deleteWeight = (material) => {
		setWeights((current) => {
			const copy = { ...current };

			delete copy[material];

			return copy;
		});

		setTareWeights((current) => {
			const copy = { ...current };

			delete copy[material];

			return copy;
		});
	};

	/**
	 *
	 * @param {string} materialType - Eg. 'HDPE', 'GLS, or 'PETE'
	 * @param {string} inputType - Eg. 'Count', 'Weight', or 'Scrap'
	 *
	 */
	const setInputType = (materialType, inputType) => {
		setSelectedInputType((prevState) => ({
			...prevState,
			[materialType]: inputType,
		}));
	};

	const showReview = () => {
		setReviewOverlay(true);
	};

	const resetTaskEnded = () => {
		setTaskEnded(false);
	};

	return (
		<>
			<RecycletekLogo />
			<Overlay open={complianceOverlay} title='User Info Required' zIndex={535}>
				<OverOneHundredUserInfoCA cancel resetRedemptionValue />
			</Overlay>
			<CheckPaymentPreference />
			<Overlay open={reviewOverlay} title={t('overlay.review.reviewAndFinalize')} zIndex={525}>
				<ReviewAndFinalize
					tareWeights={tareWeights}
					grossWeights={weights}
					taskEnded={taskEnded}
					materials={materials}
					password={password}
					setPassword={setPassword}
					closeOverlay={closeReviewOverlay}
					resetTaskEnded={resetTaskEnded}
					calculateNetWeights={calculateNetWeights}
					handleGrossWeight={handleGrossWeight}
					handleTareWeight={handleTareWeight}
					deleteWeight={deleteWeight}
					end={end}
				/>
			</Overlay>
			<CustomSnackbar
				message={snackbarMessage.text}
				status={snackbarMessage.status}
				close={closeSnackbar}
				showCloseButton
			/>
			<CustomNotification
				open={open}
				closeMessage={closeMessage}
				id={modal.id}
				heading={modal.heading}
				message={modal.message}
				disabled={isButtonDisabled}
				startMachineExpressMode={start}
			/>
			<Box className={classes.main}>
				<Box className={classes.materials}>
					<ScrollContainer>
						<TabMenu tabs={materialTabs} setTab={setTab} selected={selectedTab} />
						{materials.map(
							(material) =>
								material.selected && (
									<Box className={classes.materialContainer}>
										<ContainerInputTypes
											setType={setInputType}
											materialType={material.type}
											scrap={material.scrap}
											selectedInputTypes={selectedInputType}
										/>
										<MaterialPanel
											name={material.type}
											fullName={material.displayName}
										>
											<CountInputs
												material={material}
												show={selectedInputType[material.type] === 'Count'}
											/>
											<WeightInputs
												show={selectedInputType[material.type] === 'Weight'}
												materialType={material.type}
												handleGrossWeight={handleGrossWeight}
												handleTareWeight={handleTareWeight}
											/>
											<ScrapInputs
												show={selectedInputType[material.type] === 'Scrap'}
												materialType={material.type}
											/>
										</MaterialPanel>
									</Box>
								),
						)}
					</ScrollContainer>
				</Box>
			</Box>
			<Box className={classes.infoContainer}>
				<ExpressInfoSection />
			</Box>
			<Box className={classes.controlsContainer}>
				<ExpressControls
					isBeltRunning={isBeltRunning}
					selectMaterialToRun={selectMaterialToRun}
					taskEnded={taskEnded}
					showReview={showReview}
					stop={stop}
				/>
			</Box>
			<Box className={classes.logout}>
				<LogoutButton logout={logout} />
			</Box>
		</>
	);
}
